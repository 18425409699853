import React from 'react'
import { Dialog } from '@mui/material'
import { Transition } from '../Transition'

export const CustomMuiDialogWrapper = (props) => {
    const defaultProps = {
      TransitionComponent: Transition,
      ...props
    }
    //any props that you want to pass to the Dialog component
    return (
  
      <Dialog
        {...defaultProps}
      >
        {props.children}
      </Dialog>
    )
  }

export default CustomMuiDialogWrapper
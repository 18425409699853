import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { Transition } from '../Transition';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { MRI_URL_PATH, selectPageInsightStatusOptions } from '../MedicalsComponents/insights';
import { setToast } from '../redux/slices/globalToastSlice';
import { setMriSelectedRows, setMriFindingsUpdateLoading } from '../redux/slices/mriFindingsSlice';
import { useUpdateEntityInsightDetailsMutation } from '../services/insights/insightsApi';

const EditMRIFinding = () => {
  const dispatch = useDispatch();

  const [updateMriFinding] = useUpdateEntityInsightDetailsMutation({
    fixedCacheKey: 'update-mri-finding',
  });

  const { mriSelectedRows } = useSelector((state) => state.MriFindings);

  const isSameStatus = mriSelectedRows.every((row) => row.status === mriSelectedRows[0].status);
  const initialStatus = isSameStatus ? mriSelectedRows[0].status : '';

  const [selectedStatus, setSelectedStatus] = useState(initialStatus);

  const { editMRIFinding } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = editMRIFinding;
  const { documentId, user } = data;

  const isSameValue = mriSelectedRows.every((row) => row.value === mriSelectedRows[0].value);
  const initialValue = isSameValue ? mriSelectedRows[0].value : '';

  const [value, setValue] = useState(initialValue);

  const isDescriptionDirty = value !== initialValue;
  const isStatusDirty = selectedStatus !== initialStatus;

  const handleChangeStatus = (e) => setSelectedStatus(e.target.value);

  const handleChangeDescription = (e) => setValue(e.target.value);

  const handleSaveClick = () => {
    dispatch(setMriFindingsUpdateLoading(true));
    // Perform update
    updateMriFinding({
      documentId,
      insightsType: MRI_URL_PATH,
      entitiesToUpdate: mriSelectedRows.flatMap(({ entityIds, uuid }) => ({ entityIds, uuid })),
      newStatus: selectedStatus,
      newValue: value,
    })
      .unwrap()
      .catch((error) => {
        console.log('error', error);
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with updating MRI finding(s)' }));
        dispatch(setMriFindingsUpdateLoading(false));
      });

    dispatch(setMriSelectedRows([])); // Reset selections
    handleClose(); // Close dialog
  };

  const handleClose = () => dispatch(resetGlobalDialogues());

  return (
    <Dialog open={open} onClose={handleClose} fullWidth TransitionComponent={Transition}>
      <DialogTitle>Edit MRI findings</DialogTitle>

      <DialogContent>
        <Alert severity="info" spacing={2} sx={{ my: 2 }}>
          {mriSelectedRows.length} selected
        </Alert>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            label="Description"
            value={value}
            helperText={value ? '' : 'Multiple values'}
            fullWidth
            multiline
            maxRows={4}
            variant="outlined"
            onChange={handleChangeDescription}
          />

          <FormControl fullWidth size="small">
            <InputLabel id="select-mri-status-label">Status</InputLabel>
            <Select
              labelId="select-mri-status-label"
              id="select-mri-status"
              value={selectedStatus}
              label="Status"
              onChange={handleChangeStatus}
            >
              {selectPageInsightStatusOptions.map(({ value, label }, index) => (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{!isSameStatus ? 'Multiple values' : `Current value: ${initialStatus}`}</FormHelperText>
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>

        <Button
          disabled={!isDescriptionDirty && !isStatusDirty}
          onClick={handleSaveClick}
          variant="contained"
          color="secondary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMRIFinding;

import React from 'react';
import { useParams } from 'react-router-dom';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { UploadFileOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useGetDocumentDataQuery } from '../services/documentData/documentDataApi';
import { setUploadDocumentsDialog } from '../redux/slices/globalDialogueSlice';
import {
  DOCUMENT_UPLOADED,
  AWAITING_MEDICAL_EXTRACTION,
  WAITING_FIRM_APPROVAL,
  AWAITING_FIRM_REVIEW,
  AWAITNG_PRECEDENT_SIGNOFF,
} from '../common-demand';

const UploadDocumentsMenuItem = ({ handleMenuClose }) => {
  const { documentId } = useParams();

  const dispatch = useDispatch();

  //document data query
  const { data: documentData = {} } = useGetDocumentDataQuery(
    { documentId, pollPdfStatus: false },
    { skip: !documentId }
  );
  const { metadata } = documentData;
  const documentStatus = metadata?.documentStatus || '';

  const { isDocumentsUploading } = useSelector((state) => state.DemandDom);

  const handleShowUploadDocumentsDialog = () => {
    handleMenuClose();
    dispatch(
      setUploadDocumentsDialog({
        open: true,
        documentId,
      })
    );
  };

  const isUploadDocumentsDisabled =
    isDocumentsUploading ||
    ![
      DOCUMENT_UPLOADED,
      AWAITING_MEDICAL_EXTRACTION,
      AWAITING_FIRM_REVIEW,
      WAITING_FIRM_APPROVAL,
      AWAITNG_PRECEDENT_SIGNOFF,
    ].includes(documentStatus);

  return (
    <MenuItem
      component="label"
      disabled={isUploadDocumentsDisabled}
      onClick={handleShowUploadDocumentsDialog}
    >
      <ListItemIcon>
        <UploadFileOutlined />
      </ListItemIcon>
      <ListItemText primary="Upload documents" />
    </MenuItem>
  );
};

export default UploadDocumentsMenuItem;

import React from 'react';
import { Alert, AlertTitle, Typography, Stack, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setApiErrorToast } from '../../redux/slices/globalToastSlice';

const ApiDefaultToast = ({errorPayload, title}) => {
    const dispatch = useDispatch();
    const errorMessage = errorPayload?.message || errorPayload?.error?.message || '';
    const status = errorPayload?.status || errorPayload?.meta?.baseQueryMeta?.response?.status || errorPayload?.payload?.status || '';
    const endpoint =  errorPayload?.url || errorPayload?.payload?.url || errorPayload?.meta?.arg?.endpointName || '';

    const mailtoLink = `mailto:helpdesk@precedent.com?subject=Something went wrong in Demand Composer&body=Error Message: ${encodeURIComponent(errorMessage)}%0D%0AStatus: ${encodeURIComponent(status)}%0D%0AEndpoint: ${encodeURIComponent(endpoint)}`;

    return (
        <Alert severity="error">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <AlertTitle>{title}</AlertTitle>
                <Button
                    onClick={() => dispatch(setApiErrorToast({ isOpen: false }))}
                    href={mailtoLink}
                    sx={{
                        fontWeight: 'bold',
                        color: '#5f2120 !important',
                        mt: -1,
                    }}
                >
                    Contact help desk
                </Button>
            </Stack>
            <Typography sx={{ marginTop: 1 }}>
                If the problem persists, contact helpdesk@precedent.com.
            </Typography>
        </Alert>
    );
};


const FetchDocumentData403Toast = () => {
    const dispatch = useDispatch();
    return (
        <Alert severity="error"
            action={
                <Button
                    component={RouterLink}
                    onClick={() => dispatch(setApiErrorToast({ isOpen: false }))}
                    to="/inventory"
                    size='small'
                    sx={{
                        color: '#5f2120 !important'
                    }}
                >
                    Return to inventory
                </Button>
            }>
            You are not currently assigned to this demand.
        </Alert>
    )
}

const Generic403Toast = () => {
    const dispatch = useDispatch();
    return (
        <Alert severity="error"
            action={
                <Button
                    component={RouterLink}
                    onClick={() => dispatch(setApiErrorToast({ isOpen: false }))}
                    to="/inventory"
                    size='small'
                    sx={{
                        color: '#5f2120 !important'
                    }}
                >
                    Return to inventory
                </Button>
            }>
            You do not have permission to access this resource.
        </Alert>
    )
}

export default {
    apiDefaultToast: ApiDefaultToast,
    fetchDocumentData403Toast: FetchDocumentData403Toast,
    generic403Toast: Generic403Toast
}
import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Grid,
  DialogTitle,
  Stack,
  Checkbox,
  Box,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setEditDocumentNames } from '../redux/slices/globalDialogueSlice';
import { isDemandOrCoverLetter } from '../common-document';
import { saveDocumentFilesBydId } from '../redux/thunks/demandData';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';

const EditDocumentNames = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.GlobalDialogues.editDocumentNames);
  const { documentId, user } = data;

  const { usesDividerPages, documentMetaData } = useSelector((state) => state.Document);

  const { data: documentFiles = [], isLoading: documentFilesLoading } = useGetAllFilesByDocumentIdQuery(documentId, {
    refetchOnMountOrArgChange: true,
    skip: !documentId,
  });

  const documentData = documentFiles.map((file) => {
    return {
      id: file.fileEntityId,
      fileName: file?.fileEntityData?.fileName,
      detectedType: file?.fileEntityData?.detectedType || '',
      aliasFileName: file?.fileEntityData?.aliasFileName,
      defaultValue: file?.fileEntityData?.aliasFileName || file?.fileEntityData?.fileName,
      hasDividerPage: Boolean(file?.fileEntityData?.dividerPageId),
      originalDividerPageSetting: Boolean(file?.fileEntityData?.dividerPageId),
    };
  });

  const [formState, setFormState] = useState(
    documentData?.map((file) => {
      return {
        ...file,
        currentValue: file.defaultValue,
      };
    })
  );

  // Calculate `bulk-edit` checkbox state
  const nonLetterFiles = formState.filter(
    (file) => file.detectedType !== 'cover letter' && file.detectedType !== 'demand letter'
  );
  const allDividerPagesChecked = nonLetterFiles.every((file) => file.hasDividerPage);
  const noDividerPagesChecked = nonLetterFiles.every((file) => !file.hasDividerPage);
  const isIndeterminate = !allDividerPagesChecked && !noDividerPagesChecked;

  const anyErrors = formState.some((file) => !file.currentValue);
  const allChanged = formState.filter(
    (file) => file.defaultValue !== file.currentValue || file.hasDividerPage !== file.originalDividerPageSetting
  );
  const anyChange = allChanged.length > 0;

  const handleClose = () => {
    dispatch(setEditDocumentNames({ open: false, data: {} }));
  };

  const handleSave = () => {
    const updatedFiles = allChanged.map((file) => {
      return {
        id: file.id,
        aliasFileName: file.currentValue,
        hasDividerPage: file.hasDividerPage, // Include updated divider page info
      };
    });

    dispatch(saveDocumentFilesBydId({ documentId, files: updatedFiles, user }));
    handleClose();
  };

  const handleSelectAll = (e) => {
    setFormState(
      formState.map((file) => {
        if (!documentMetaData.demandTemplateId || isDemandOrCoverLetter(file.detectedType)) {
          return file;
        }

        return {
          ...file,
          hasDividerPage: e.target.checked,
        };
      })
    );
  };

  return (
    <>
      <DialogTitle>
        {usesDividerPages ? 'Edit display names and divider pages' : 'Edit document display names'}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: 0.5,
          }}
        >
          <Grid item xs={12}>
            <Stack direction="row" alignItems={'center'}>
              <Box
                sx={{
                  width: usesDividerPages ? '70%' : '100%',
                }}
              >
                <Typography variant="body2" align="left" color={'text.secondary'}>
                  Display names
                </Typography>
              </Box>
              {usesDividerPages && (
                <Stack
                  direction={'row'}
                  sx={{
                    width: '30%',
                  }}
                  spacing={1}
                  alignItems={'center'}
                >
                  <Checkbox
                    id="bulk-edit"
                    onClick={handleSelectAll}
                    checked={allDividerPagesChecked}
                    indeterminate={isIndeterminate}
                  />
                  <Typography variant="body2" align="left" color={'text.secondary'}>
                    Divider page
                  </Typography>
                </Stack>
              )}
            </Stack>
            {documentData?.map((file, idx) => {
              const hasChanged =
                file.defaultValue !== formState[idx].currentValue ||
                file.hasDividerPage !== formState[idx].hasDividerPage;
              const hasNoAlias = !file.aliasFileName && !hasChanged;
              const currentValue = formState[idx].currentValue;
              const aliasMatchesFileName = currentValue === file.fileName;

              return (
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Box
                    sx={{
                      width: usesDividerPages ? '70%' : '100%',
                      pr: 2,
                    }}
                  >
                    <TextField
                      value={currentValue}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setFormState((prev) => {
                            const newState = [...prev];
                            newState[idx].currentValue = file.defaultValue;
                            return newState;
                          });
                        }
                      }}
                      onChange={(e) => {
                        setFormState((prev) => {
                          const newState = [...prev];
                          newState[idx].currentValue = e.target.value;
                          return newState;
                        });
                      }}
                      required
                      label={`Display name for ${file.fileName}`}
                      fullWidth
                      size="small"
                      margin="normal"
                      error={!currentValue}
                      sx={{
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: !currentValue && 'error.main',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: !currentValue && 'error.main',
                        },
                        '& .MuiFormLabel-root': {
                          color: !currentValue && 'error.main',
                        },
                      }}
                    />
                  </Box>
                  {usesDividerPages && (
                    <Box
                      sx={{
                        width: '30%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'left',
                      }}
                    >
                      <Tooltip
                        title={
                          (isDemandOrCoverLetter(file.detectedType) &&
                            `Divider page not available before the ${file.detectedType}`) ||
                          (!documentMetaData.demandTemplateId && 'Divider pages not available on no-template demands')
                        }
                        arrow
                        placement="left"
                      >
                        <Box
                          sx={{
                            cursor: isDemandOrCoverLetter(file.detectedType) ? 'not-allowed' : 'default',
                          }}
                        >
                          <Checkbox
                            disabled={isDemandOrCoverLetter(file.detectedType) || !documentMetaData.demandTemplateId}
                            checked={formState[idx].hasDividerPage}
                            onChange={(e) => {
                              setFormState((prev) => {
                                const newState = [...prev];
                                newState[idx].hasDividerPage = e.target.checked;
                                return newState;
                              });
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  )}
                </Stack>
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={() => handleClose()}>
          Cancel
        </Button>{' '}
        <Button disabled={!anyChange || anyErrors} variant={'contained'} color={'secondary'} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default EditDocumentNames;

import React, { useEffect, useState, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Switches, Radios, Autocomplete } from 'mui-rff'
import { deepPurple } from '@mui/material/colors';
import { Stack, Button, InputAdornment, Dialog, IconButton, CircularProgress, DialogContent, DialogTitle, Typography, MenuItem, FormControl, Tabs, Tab, DialogActions, Divider, Box, TextField as MuiTextField, Badge } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AutoAwesome, Close } from '@mui/icons-material';
import Title from '../../CommonComponents/Title';
import { theme } from '../../Theme';
import ResizableWrapWithPdfViewer from '../../CommonComponents/ResizableWrapWithPdfViewer';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import ConfiguredRichTextEditor from '../RichTextEditor/ConfiguredRichTextEditor';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerAvailableTemplatesAndCustomFields } from '../../redux/thunks/customerData';
import { setDemandDetailsEditOpen } from '../../redux/slices/demandDomSlice';
import TemplateUpdateAlert from '../../DemandComponents/TemplateUpdateAlert';
import { shouldDisplayAlert } from '../../DemandComponents/TemplateUpdateAlert';
import { formatDecimal, cleanNumber, formatDateForForm, getCurrentDate, formatPhoneNumber, userHasPermission } from '../../common';
import { isPrecedentUser } from '../../common-roles';
import { PRECEDENT_VIEW_TYPE, LAW_FIRM_VIEW_TYPE } from '../../common-view-types';
import { coverageList, stateLabelValues } from '../../common-data';
import { joinNames, mapCustomerDemandFieldsToDocumentFields, validate, NO_TEMPLATE, buildUserAutoCompleteData, getSingleSelectAutoCompleteFilteredOptions, getMultiSelectAutoCompleteFilteredOptions } from './FormHelpers'
import { parseNestedObject } from '../../helpers/formHelpers';
import DemandResponseDueDateFields from './SharedFields/DemandResponseDateFields';
import { DiscardWork } from '../../dialogs/DialogText';
import { debounce } from 'lodash';
import { useGetPdfUrlQuery } from '../../services/pdf/pdfApi';
import { useSaveDocumentDataMutation, useLazyGetDocumentDataQuery } from '../../services/documentData/documentDataApi';
import { useParams } from 'react-router-dom';
import { handleDemandEditingSave } from './Utilities';
import EditDemandDetailsSkeleton from './EditDemandDetailsSkeleton';
import { fetchTemplateData, fetchTemplateSpecificVersionData } from '../../redux/thunks/templateData';
import { getConfigValue } from '../../api';
import AiReferenceTable from '../AiReferences/AiReferencesTable';
import ReferenceFeedback from '../AiReferences/ReferenceFeedback';
import { resetAiNarratives } from '../../services/aiNarrativeGeneration/customSlice';
import { useFetchAuthorFormDataQuery } from '../../services/authFormData/authFormDataApi';
import SearchIntegrationButton from './Integrations/SearchIntegrationButton';
import { useBulkEditConfirmationDialog } from '../../dialogs/contexts/DialogContext';
import { setIsNewCaseSelected } from '../../redux/slices/integrationsSlice';

const badgeStyleProps = { '.MuiBadge-badge': { right: '6px' } };
const a11yProps = (index) => ({ id: `edit-demand-details-tab-${index}`, 'aria-controls': `edit-demand-details-tabpanel-${index}`, });

const EditDemandDetails = ({ setIsSaving, documentData, documentMetaData }) => {
  const { documentId } = useParams();
  const dispatch = useDispatch();
  const { setBulkEditConfirmation } = useBulkEditConfirmationDialog();

  const { user, userData } = useSelector((state) => state.User);
  const viewType = isPrecedentUser(userData) ? PRECEDENT_VIEW_TYPE : LAW_FIRM_VIEW_TYPE;
  const { customerData, availableTemplatesAndCustomFields } = useSelector((state) => state.Customer);

  const { integratedCase: { id: matterId, claimNumber, clientName }, integrationData: { integrationId }, isNewCaseSelected } =  useSelector((state) => state.Integrations);
  const isIntegrationCaseLinked = matterId && claimNumber;

  const { data: { demandS3Url } = {}, isLoading: isPdfLoading } = useGetPdfUrlQuery(documentId, { skip: !documentId, });
  const { demandS3RegenerationSkeletonData, demandS3RegenerationLoadingData, currentPage, currentBoundingBoxes, currentPageTrigger } = useSelector((state) => state.PdfCustomSlice);
  const isPdfRegnerationLoading = Boolean(demandS3RegenerationLoadingData[documentId] || demandS3RegenerationSkeletonData[documentId]);

  const { data: authorFormData = {} } = useFetchAuthorFormDataQuery({ ...(viewType === PRECEDENT_VIEW_TYPE && { customerId: customerData?.customerId }) });
  const { users = [], approvers = [] } = authorFormData;

  const [triggerGetDocumentData, { data: DocumentDataFetchResponseDictionary, isLoading: isDocumentLoading }] = useLazyGetDocumentDataQuery();
  const { document, metadata, deliveryAttorneyEmail, deliveryAttorneyName, deliveryAttorneyPhoneNumber } = DocumentDataFetchResponseDictionary || {};
  const [saveDocumentData, { isLoading: submitting, isSuccess: isSaveSuccessful, isError: hasSaveError, error: saveError }] = useSaveDocumentDataMutation();

  const initialDemandTemplateId = useMemo(() => { return metadata?.demandTemplateId ? availableTemplatesAndCustomFields.find(template => template.templateId === metadata?.demandTemplateId)?.templateId : NO_TEMPLATE }, [availableTemplatesAndCustomFields, metadata?.demandTemplateId]);
  const { templateBody, templateBodyForPinnedVersion } = useSelector((state) => state.Template);
  const shouldShowTemplateUpdateAlert = useMemo(() => shouldDisplayAlert(templateBody, templateBodyForPinnedVersion, metadata), [templateBody, templateBodyForPinnedVersion, metadata]);

  const { fieldsWithGeneratedNarrative, aiReferencesForField } = useSelector(state => state.AiNarrativeGeneration);

  const [templateDialogState, setTemplateDialogState] = useState({
    confirmNoTemplate: { open: false, text1: 'The existing demand letter will be removed from documents.', text2: 'Remember to upload a replacement.', buttonText1: 'Cancel', buttonText2: 'Confirm selection', },
    confirmTemplateChange: { open: false, text1: 'A pre-filled demand letter will be added to the documents.', text2: 'Remember to remove uploaded demand letters.', buttonText1: 'Cancel', buttonText2: 'Use selected template', },
  });

  const [defaultRelativeDueDate, setDefaultRelativeDueDate] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [tabDirtyState, setTabDirtyState] = useState({ 0: false, 1: false });
  const isEitherTabDirty = tabDirtyState[0] || tabDirtyState[1];

  // mappping data
  const approverIds = approvers?.map(approver => approver.userId) || [];
  const attorneyUsers = users ?? [].filter(user => approverIds.includes(user?.userId));
  const matchingAttorney = attorneyUsers.find(attorney => attorney?.userId === document?.sendingFirm?.attorney);
  const defaultAttorneyObject = { userId: document?.sendingFirm?.attorney, firstName: matchingAttorney?.firstName, lastName: matchingAttorney?.lastName, contactInfo: { phoneNumber: matchingAttorney?.phone, emailAddress: matchingAttorney?.emailAddress } };
  const attorneys = !matchingAttorney ? [defaultAttorneyObject, ...attorneyUsers] : attorneyUsers;
  const attorney_auto_complete_data = buildUserAutoCompleteData(attorneys);
  const user_data_for_auto_completes = buildUserAutoCompleteData(users);
  const primaryContact_auto_complete_data = user_data_for_auto_completes;
  const collaborators_auto_complete_data = user_data_for_auto_completes;
  const customerDemandFields = useMemo(() => customerData?.customerEntityData?.customDemandFieldsList || [], [customerData?.customerEntityData?.customDemandFieldsList]);
  const documentDemandFields = useMemo(() => document?.customDemandFieldsList || [], [document?.customDemandFieldsList]);



  useEffect(() => {
    //onLoad
    getConfigValue('defaultRelativeDueDate', null, user)
      .then((response) => setDefaultRelativeDueDate(response ? response : 30))

    triggerGetDocumentData({ documentId, pollPdfStatus: false });

    return () => dispatch(resetAiNarratives()); // reset AI references and feedback on unmount
  }, []);

  const [initialized, setInitialized] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchTemplateDataPromise = dispatch(fetchTemplateData({ templateId: documentMetaData.demandTemplateId, user }));
        const fetchTemplateSpecificVersionDataPromise = dispatch(fetchTemplateSpecificVersionData({ templateId: documentMetaData.demandTemplateId, version: documentMetaData.demandTemplatePinnedVersion, user }));
        const fetchCustomerAvailableTemplatesAndCustomFieldsPromise = dispatch(fetchCustomerAvailableTemplatesAndCustomFields({ user, customerId: customerData?.customerId }));

        // Wait for all dispatches to complete
        await Promise.all([
          fetchTemplateDataPromise,
          fetchTemplateSpecificVersionDataPromise,
          fetchCustomerAvailableTemplatesAndCustomFieldsPromise,
        ]);
        setInitialized(true);
      } catch (error) {
        console.error("Error fetching template data:", error);
        // Handle the error appropriately
      }
    };

    fetchData(); // Call the async function
  }, [documentMetaData?.demandTemplateId, documentData?.version]);

  const initialValues = useMemo(() => {
    const initialAttorney = { label: joinNames(documentData?.sendingFirm?.attorney), value: documentData?.sendingFirm?.attorney };
    const initialPrimaryContact = { label: joinNames(documentData?.sendingFirm?.primaryContact), value: documentData?.sendingFirm?.primaryContact };
    const attorneyIsPrimaryContact = joinNames(documentData?.sendingFirm?.attorney) === joinNames(documentData?.sendingFirm?.primaryContact);

    const initialCollaborators = (documentData?.sendingFirm?.caseManagers ? documentData?.sendingFirm?.caseManagers.map(manager => (
      { label: joinNames(manager), value: manager }
    )) : undefined);

    const isUsingRelativeDueDate = typeof documentData?.demandDetails?.usingRelativeDueDate === 'boolean' ? documentData?.demandDetails?.usingRelativeDueDate : false;

    return {
      ...documentData,
      recipientCarrier: {
        ...documentData?.recipientCarrier,
        contactInfo: {
          ...documentData?.recipientCarrier?.contactInfo,
          phoneNumber: formatPhoneNumber(documentData?.recipientCarrier?.contactInfo?.phoneNumber),
          address: {
            ...documentData?.recipientCarrier?.contactInfo?.address,
            zipCode: documentData?.recipientCarrier?.contactInfo?.address?.zipCode,
          },
        },
      },
      recipientAdjuster: {
        ...documentData?.recipientAdjuster,
        contactInfo: {
          ...documentData?.recipientAdjuster?.contactInfo,
          phoneNumber: formatPhoneNumber(documentData?.recipientAdjuster?.contactInfo?.phoneNumber),
        },
      },
      metadata: {
        ...documentData?.metadata,
        demandTemplateId: initialDemandTemplateId,
      },
      claimInfo: {
        ...documentData?.claimInfo,
        dateOfLoss: formatDateForForm(documentData?.claimInfo?.dateOfLoss),
      },
      demandDetails: {
        ...documentData?.demandDetails,
        usingRelativeDueDate: isUsingRelativeDueDate,
        demandResponseDueDate: isUsingRelativeDueDate ? '' : formatDateForForm(documentData?.demandDetails?.demandResponseDueDate),
        demandResponseRelativeDueDate: isUsingRelativeDueDate ? (documentData?.demandDetails?.demandResponseRelativeDueDate || defaultRelativeDueDate) : '',
        displayDemandAmount: typeof documentData?.demandDetails?.displayDemandAmount === 'boolean' ? documentData?.demandDetails?.displayDemandAmount : false,
        policyLimitDemandIndicator: typeof documentData?.demandDetails?.policyLimitDemandIndicator === 'boolean' ? documentData?.demandDetails?.policyLimitDemandIndicator : false,
        demandOfferToSettleAmount: documentData?.demandDetails?.policyLimitDemandIndicator
          ? documentData?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm
          : documentData?.demandDetails?.demandOfferToSettleAmount,
      },
      customDemandFieldsList: mapCustomerDemandFieldsToDocumentFields(customerDemandFields, documentDemandFields),
      mutators: {
        attorneyIsPrimaryContact,
        collaborators: documentData?.sendingFirm?.caseManagers?.map(manager => joinNames(manager)) || [],
      },
      autoCompletes: {
        collaborators: initialCollaborators,
        attorney: initialAttorney,
        primaryContact: attorneyIsPrimaryContact ? initialAttorney : initialPrimaryContact,
        carrier: {
          label: 'validation placeholder for read only/ unused field',
          value: 'validation placeholder for read only/ unused field'
        }
      },
    };
  }, [documentData, customerDemandFields, initialDemandTemplateId, defaultRelativeDueDate]);

  const [dialogState, setDialogState] = useState({ discardChanges: false });

  const handleSaveClick = async (values) => {
    const isUsingRelativeDueDate = Boolean(values?.demandDetails?.usingRelativeDueDate);

    const saveBody = parseNestedObject({
      ...values,
      customDemandFieldsList: mapCustomerDemandFieldsToDocumentFields(customerDemandFields, values?.customDemandFieldsList || []),
      demandDetails: {
        ...values.demandDetails,
        demandOfferToSettleAmount: cleanNumber(values.demandDetails.demandOfferToSettleAmount),
        demandResponseDueDate: isUsingRelativeDueDate ? '' : values?.demandDetails?.demandResponseDueDate,
        demandResponseRelativeDueDate: isUsingRelativeDueDate ? values?.demandDetails?.demandResponseRelativeDueDate : '',
      },
      claimInfo: {
        ...values.claimInfo,
        insuredPolicyLimitsAsUnderstoodByFirm: cleanNumber(values.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm),
      },
      sendingFirm: {
        ...values.sendingFirm,
        attorney: values?.autoCompletes?.attorney?.value,
        primaryContact: values?.mutators?.attorneyIsPrimaryContact ? values?.autoCompletes?.attorney?.value : values?.autoCompletes?.primaryContact?.value,
        caseManagers: (values?.autoCompletes?.collaborators || []).map(collaborator => collaborator.value)
      },
      ...(isIntegrationCaseLinked && {
        caseManagementMetadata: {
          sourceSystemId: integrationId,
          matterId: claimNumber,
          matterTechId: matterId,
        },
      }),
    });

    delete saveBody.mutators;
    delete saveBody.autoCompletes;

    handleDemandEditingSave({
      data: saveBody,
      callbacks: { handleClose: closeEditDemandDetails },
      setIsSaving,
      dispatch,
      saveDocumentData,
      triggerGetDocumentData,
      documentId
    });
  };

  const confirmationDialogProps = {
    dialogOpen: dialogState.discardChanges,
    dialogText: DiscardWork.text,
    cancelButtonText: DiscardWork.cancelText,
    okButtonText: DiscardWork.confirmText,
    handleCancel: () => { setDialogState({ ...dialogState, discardChanges: false }); }
  };

  const handleTabChange = (e) => setTabValue(e.target.tabIndex);

  const handleCloseButtonClick = () => { isEitherTabDirty ? setDialogState({ discardChanges: true }) : closeEditDemandDetails() };

  const closeEditDemandDetails = () => {
    dispatch(setDemandDetailsEditOpen(false));
    dispatch(resetAiNarratives());
  };

  return (
    <DialogContent sx={{ marginTop: '0px', paddingTop: '0px', overflow: 'hidden', paddingLeft: '0px', paddingRight: '0px' }}>

      <Form
        validate={(values) => validate({ values, userData })}
        onSubmit={handleSaveClick}
        initialValues={initialValues}
        render={({
          handleSubmit,
          form,
          submitting,
          initialValues,
          values,
          valid,
          errors,
          dirty,
          touched,
        }) => {
          if (tabValue === 0 && dirty !== tabDirtyState[0]) setTabDirtyState({ ...tabDirtyState, 0: dirty });
          if (tabValue === 1 && dirty !== tabDirtyState[1]) setTabDirtyState({ ...tabDirtyState, 1: dirty });

          const noTemplatedInitially = initialDemandTemplateId === NO_TEMPLATE;
          if (!noTemplatedInitially) { setCurrentTemplate(availableTemplatesAndCustomFields.find((template) => template.templateId === values.metadata?.demandTemplateId)); }
          const handleTemplateCancelChange = () => { form.change('metadata.demandTemplateId', metadata?.demandTemplateId || NO_TEMPLATE); handleTemplateDialogClose(); }
          const handleTemplateOkChange = () => { setCurrentTemplate(availableTemplatesAndCustomFields.find((template) => template.templateId === values.metadata?.demandTemplateId)); handleTemplateDialogClose(); }
          const handleTemplateDialogClose = () => { setTemplateDialogState({ confirmNoTemplate: { ...templateDialogState.confirmNoTemplate, open: false }, confirmTemplateChange: { ...templateDialogState.confirmTemplateChange, open: false }, }); };

          if (isNewCaseSelected) {
            const handleFormUpdate = () => {
              form.change('sendingFirm.firmCaseNumber', claimNumber);
              form.change('claimInfo.claimant.firstName', clientName);
            };
            const rows = [
              {
                field: 'sendingFirm.firmCaseNumber',
                headerName: "Matter number or Case ID",
                oldValue: initialValues.sendingFirm.firmCaseNumber,
                newValue: claimNumber,
              },
              {
                field: 'claimInfo.claimant.firstName',
                headerName: "Client",
                oldValue: initialValues.claimInfo.claimant.firstName,
                newValue: clientName,
              },
            ];
            setBulkEditConfirmation({ open: true, handleOk: handleFormUpdate, selectedCount: 2, source: 'editDemandDetails', rows });
            dispatch(setIsNewCaseSelected(false));
          }

          return (
            <form id="editDemandDetails" onSubmit={handleSubmit}>

              <DialogTitle
                className="dialog-title-blue"
                sx={{ height: '60px', width: '100%', zIndex: 1000 }}
                display="flex"
                alignItems="center"
                position={'fixed'}
              >
                <Stack
                  direction={'row'}
                  style={{ width: '100%' }}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <IconButton
                      onClick={handleCloseButtonClick}
                      aria-label="close"
                      sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.15)' } }}
                    >
                      <Close color={'white'} />
                    </IconButton>
                    <Title color={theme.palette.white.main}>Edit demand details</Title>
                  </Stack>
                  <Button
                    disabled={submitting || !valid || !(isEitherTabDirty)}
                    variant="contained"
                    color="white"
                    sx={{
                      '&:disabled': {
                        backgroundColor: 'rgba(255,255,255,0.2)',
                      },
                    }}
                    type="submit"
                    form="editDemandDetails"
                    startIcon={(submitting) && <CircularProgress size={20} color="inherit" />} // Show spinner when loading
                  >
                    {submitting ? 'Saving...' : 'Save'}
                  </Button>
                </Stack>
              </DialogTitle>

              <div style={{ width: '100%', display: 'flex', padding: '20px' }}>
                <ResizableWrapWithPdfViewer
                  defaultWidth={'60%'}
                  pdfHeight={'90vh'}
                  user={user}
                  documentId={documentId}
                  currentPage={currentPage}
                  currentBoundingBoxes={currentBoundingBoxes}
                  currentPageTrigger={currentPageTrigger}
                  dividerTopMargin={'50px'}
                >
                  <>
                    {(!initialValues || !initialized) && (
                      <Box
                        sx={{ mt: 4 }}
                      >
                        <EditDemandDetailsSkeleton />
                      </Box>
                    )}
                    {initialValues && initialized && (
                      <div style={demandS3Url ?
                        { minHeight: '90vh', marginTop: '50px' } :
                        {}
                      }>

                        <Tabs
                          value={tabValue}
                          onChange={handleTabChange}
                          aria-label="Edit demand details form tabs"
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: !valid && tabValue === 0 ? theme.palette.error.main : theme.palette.primary.main,
                            }
                          }}
                        >
                          <Tab label="Demand details" {...a11yProps(0)} tabIndex={0} sx={{ color: !valid && theme.palette.error.main, '&.Mui-selected': { color: !valid && theme.palette.error.main } }} />
                          <Tab label="Customize demand letter" {...a11yProps(1)} tabIndex={1} icon={false ? <Badge badgeContent='1' color="error" sx={badgeStyleProps} /> : ''} iconPosition="start" /> </Tabs>

                        <Stack spacing={2} sx={{ paddingRight: 2, pb: 2, height: 'calc(100vh - 120px)', overflow: 'scroll' }}>

                          {tabValue === 0 && (
                            <Grid container spacing={2} sx={{ mb: 5 }}>
                              <Stack spacing={2} width={"100%"} sx={{ mt: 2, mb: 5 }} divider={<Divider flexItem />}>
                                <SearchIntegrationButton source="edit" />

                                <Stack>
                                  <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case details</Typography>
                                  <Grid container spacing={2}>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth required label="Matter number or Case ID" size='small' name='sendingFirm.firmCaseNumber' error={errors.sendingFirm?.firmCaseNumber} helperText={errors.sendingFirm?.firmCaseNumber} />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Claim number" size='small' name='claimInfo.claimNumber' />
                                    </Grid>
                                    <Grid xs={12} md={12}>
                                      <Stack spacing={1}>

                                        <TextField fullWidth required label="Client" name='claimInfo.claimant.firstName' size='small'
                                          error={errors.claimInfo?.claimant?.firstName}
                                          helperText={errors.claimInfo?.claimant?.firstName}
                                          onChange={(e) => {
                                            form.change('claimInfo.claimant.firstName', e.target.value)
                                            if (values.claimInfo?.clientIsInjuredParty) {
                                              form.change('claimInfo.injuredPartyFullName', e.target.value);
                                            }
                                          }}
                                        />

                                        <Switches
                                          name="claimInfo.clientIsInjuredParty"
                                          onChange={(e) => {
                                            const checked = e.target.checked;
                                            form.change('claimInfo.clientIsInjuredParty', checked)
                                            if (checked) {
                                              form.change('claimInfo.injuredPartyFullName', values?.claimInfo?.claimant?.firstName)
                                            } else {
                                              form.change('claimInfo.injuredPartyFullName', '')
                                            }
                                          }}
                                          data={{ label: 'Client is injured party', value: true }}
                                          color="secondary"
                                        />
                                        {!values.claimInfo?.clientIsInjuredParty &&
                                          <Box
                                            sx={{
                                              paddingLeft: 6,
                                              paddingBottom: 2
                                            }}
                                          >
                                            <TextField fullWidth required label="Injured party" name='claimInfo.injuredPartyFullName' size='small'
                                              error={errors.claimInfo?.injuredPartyFullName}
                                              helperText={errors.claimInfo?.injuredPartyFullName}
                                            />
                                          </Box>
                                        }
                                      </Stack>
                                    </Grid>

                                    <Grid xs={12} md={12}>
                                      <Stack spacing={1}>
                                        <TextField fullWidth required label="Insured" name="claimInfo.insuredFirstName" size='small'
                                          error={errors.claimInfo?.insuredFirstName}
                                          helperText={errors.claimInfo?.insuredFirstName}
                                          onChange={(e) => {
                                            form.change('claimInfo.insuredFirstName', e.target.value)

                                            if (values.claimInfo?.insuredIsTortfeasor) {
                                              form.change('claimInfo.tortfeasorFullName', e.target.value)
                                            }
                                          }}
                                        />
                                        <Switches
                                          name="claimInfo.insuredIsTortfeasor"
                                          onChange={(e) => {
                                            const checked = e.target.checked;
                                            form.change('claimInfo.insuredIsTortfeasor', checked)
                                            if (checked) {
                                              form.change('claimInfo.tortfeasorFullName', values?.claimInfo?.insuredFirstName)
                                            } else {
                                              form.change('claimInfo.tortfeasorFullName', '')
                                            }
                                          }}
                                          data={{ label: 'Insured is tortfeasor', value: true }}
                                          color="secondary"
                                        />
                                        {!values.claimInfo?.insuredIsTortfeasor &&
                                          <Box
                                            sx={{
                                              paddingLeft: 6
                                            }}
                                          >
                                            <TextField fullWidth required label="Tortfeasor" name='claimInfo.tortfeasorFullName' size='small'
                                              error={errors.claimInfo?.tortfeasorFullName}
                                              helperText={errors.claimInfo?.tortfeasorFullName}
                                            />
                                          </Box>
                                        }
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </Stack>

                                {/* loss details */}
                                <Stack>
                                  <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Loss details</Typography>
                                  <Grid container spacing={2}>
                                    <Grid xs={12} md={6}>
                                      <TextField
                                        name='claimInfo.dateOfLoss'
                                        fullWidth={true}
                                        inputProps={{ max: getCurrentDate() }}
                                        label="Date of loss" type="date"
                                        InputLabelProps={{ shrink: true }}
                                        size='small'
                                        required
                                        //we are using MUI textfield here b/c datepicker is better; therefore, we need to add below props to explicitly create controlled component
                                        onChange={e => form.change('claimInfo.dateOfLoss', e.target.value)}
                                        value={values?.claimInfo?.dateOfLoss}
                                        error={errors.claimInfo?.dateOfLoss}
                                        helperText={errors.claimInfo?.dateOfLoss}
                                      />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Coverage" name='claimInfo.claimCoverage' select size='small'>
                                        {coverageList.map((option) => (
                                          <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Loss state" name="claimInfo.lossState" size='small' select required>
                                        {[{ label: 'Select a state', value: 'none' }, ...stateLabelValues].map((option, idx) => (
                                          <MenuItem key={idx} value={option.value} disabled={idx === 0} >
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Loss county" name="claimInfo.lossCounty" size='small' />
                                    </Grid>
                                  </Grid>

                                </Stack>

                                <Stack>
                                  <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Carrier details</Typography>
                                  <Grid container spacing={2}>

                                    <Grid xs={12}>
                                      <TextField fullWidth label="Carrier" name='recipientCarrier.carrierCommonName' size='small' disabled />
                                    </Grid>

                                    <Grid xs={12}>
                                      <TextField fullWidth label="Carrier phone number" name='recipientCarrier.contactInfo.phoneNumber' type='tel' size='small'
                                        onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                        error={errors.recipientCarrier?.contactInfo?.phoneNumber}
                                        helperText={errors.recipientCarrier?.contactInfo?.phoneNumber}
                                      />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Address line 1" name="recipientCarrier.contactInfo.address.street1" size='small' />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Address line 2" name="recipientCarrier.contactInfo.address.street2" size='small' />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                      <TextField fullWidth label="City" name="recipientCarrier.contactInfo.address.city" size='small' />
                                    </Grid>
                                    <Grid xs={6} md={4}>
                                      <TextField fullWidth label="State" name="recipientCarrier.contactInfo.address.state" size='small' select>
                                        {stateLabelValues.map((option) => (
                                          <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid xs={6} md={4}>
                                      <TextField fullWidth label="Zip" name="recipientCarrier.contactInfo.address.zipCode" size='small' />
                                    </Grid>

                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Adjuster first name" name='recipientAdjuster.firstName' size='small' />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Adjuster last name" name='recipientAdjuster.lastName' size='small' />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Adjuster phone" name='recipientAdjuster.contactInfo.phoneNumber' type='tel' size='small'
                                        onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                        error={errors.recipientAdjuster?.contactInfo?.phoneNumber}
                                        helperText={errors.recipientAdjuster?.contactInfo?.phoneNumber}
                                      />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Adjuster email" name='recipientAdjuster.contactInfo.emailAddress' type='email' size='small' />
                                    </Grid>
                                  </Grid>
                                </Stack>

                                {/* demand details */}
                                <Stack>
                                  <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Demand details</Typography>
                                  <Grid container spacing={2}>
                                    <Grid xs={12}>
                                      <Stack direction={'row'} spacing={2}>
                                        <FormControl fullWidth>
                                          <Radios
                                            color="secondary"
                                            sx={{ width: '100%' }}
                                            label="Display demand amount in letter?"
                                            name="demandDetails.displayDemandAmount"
                                            onChange={(e) => {
                                              form.change(
                                                'demandDetails.displayDemandAmount',
                                                e.target.value === 'true'
                                              );
                                              if (e.target.value !== 'true') {
                                                form.change('claimInfo.insuredPolicyLimitsAsUnderstoodByFirm', '');
                                                form.change('demandDetails.demandOfferToSettleAmount', '');
                                                form.change('demandDetails.policyLimitDemandIndicator', false);
                                              } else {
                                                form.change('demandDetails.policyLimitDemandIndicator', true);
                                                form.change(
                                                  'demandDetails.demandOfferToSettleAmount',
                                                  values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm || ''
                                                );
                                              }
                                            }}
                                            data={[
                                              { label: 'Yes', value: true },
                                              { label: 'No', value: false },
                                            ]}
                                            radioGroupProps={{ row: true }}
                                            required
                                          />
                                        </FormControl>

                                        {values?.demandDetails?.displayDemandAmount !== false && (
                                          <FormControl fullWidth>
                                            <Radios
                                              color="secondary"
                                              sx={{ width: '100%' }}
                                              label="Is this a policy limit demand?"
                                              name="demandDetails.policyLimitDemandIndicator"
                                              onChange={(e) => {
                                                form.change(
                                                  'demandDetails.policyLimitDemandIndicator',
                                                  e.target.value === 'true'
                                                );
                                                if (e.target.value === 'true') {
                                                  form.change(
                                                    'demandDetails.demandOfferToSettleAmount',
                                                    values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm
                                                  );
                                                } else {
                                                  form.change('demandDetails.demandOfferToSettleAmount', '');
                                                }
                                              }}
                                              data={[
                                                { label: 'Yes', value: true },
                                                { label: 'No', value: false },
                                              ]}
                                              radioGroupProps={{ row: true }}
                                            />
                                          </FormControl>
                                        )}
                                      </Stack>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                      <TextField
                                        label="Insured policy limit"
                                        name='claimInfo.insuredPolicyLimitsAsUnderstoodByFirm'
                                        onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                        onChange={(e) => {
                                          if (values?.demandDetails?.policyLimitDemandIndicator) {
                                            form.change("demandDetails.demandOfferToSettleAmount", e.target.value)
                                          }
                                          form.change("claimInfo.insuredPolicyLimitsAsUnderstoodByFirm", e.target.value)
                                        }}
                                        size='small'
                                      />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                      <TextField
                                        fullWidth
                                        label="Demand amount"
                                        name='demandDetails.demandOfferToSettleAmount'
                                        onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                        disabled={values?.demandDetails?.policyLimitDemandIndicator}
                                        size='small'
                                      />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                      <TextField fullWidth label="Tax ID" name="sendingFirm.firmTINNumber" size='small' />
                                    </Grid>

                                    <DemandResponseDueDateFields
                                      form={form}
                                      values={values}
                                      initialValues={initialValues}
                                      errors={errors}
                                      isPdfRegnerationLoading={isPdfRegnerationLoading}
                                      gridPadding={1}
                                      topMargin='-10px'
                                      defaultRelativeDueDate={defaultRelativeDueDate}
                                    />

                                  </Grid>
                                </Stack>

                                {/* case team */}
                                <Stack>
                                  <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case team</Typography>
                                  <Grid container spacing={2}>

                                    <Grid xs={12}>

                                      <Grid xs={12}>
                                        <Autocomplete
                                          name="autoCompletes.attorney"
                                          //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                          fullWidth
                                          blurOnSelect={true}
                                          options={attorney_auto_complete_data}
                                          filterSelectedOptions={true}
                                          filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                          isOptionEqualToValue={(option, value) => option.label === value.label}
                                          renderInput={(params) => (
                                            <>
                                              <MuiTextField
                                                {...params}
                                                size='small'
                                                label="Attorney"
                                                required={true}
                                                onClick={(e) => form.blur('autoCompletes.attorney')}
                                                error={touched['autoCompletes.attorney'] && errors.autoCompletes?.attorney}
                                                helperText={touched['autoCompletes.attorney'] && errors.autoCompletes?.attorney}

                                              />
                                            </>
                                          )}
                                        />
                                      </Grid>

                                    </Grid>

                                    <Grid xs={12}>
                                      <Autocomplete
                                        name="autoCompletes.collaborators"
                                        //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                        fullWidth
                                        multiple
                                        limitTags={4}
                                        filterOptions={(options, params) => getMultiSelectAutoCompleteFilteredOptions(options, params, values?.autoCompletes?.collaborators)}
                                        options={collaborators_auto_complete_data}
                                        renderInput={(params) => (
                                          <>
                                            <MuiTextField
                                              //MUI-RFF textfield does filtering does not work well with multi select autocomplete, so we use MUI textfield here
                                              {...params}
                                              size='small'
                                              label="Collaborators"
                                            />
                                          </>
                                        )}
                                      />
                                    </Grid>

                                    <Grid xs={12}>
                                      <Switches
                                        name="mutators.attorneyIsPrimaryContact"
                                        onChange={(e) => {
                                          const checked = e.target.checked;
                                          form.change('mutators.attorneyIsPrimaryContact', checked)
                                          form.change('autoCompletes.primaryContact', checked ? values?.autoCompletes?.attorney : undefined)
                                        }}
                                        data={{ label: 'Attorney is primary contact', value: true }}
                                        color="secondary"
                                      />
                                    </Grid>
                                    {!values?.mutators?.attorneyIsPrimaryContact &&

                                      <Grid xs={12}>
                                        <Autocomplete
                                          name="autoCompletes.primaryContact"
                                          //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                          fullWidth
                                          blurOnSelect={true}
                                          filterSelectedOptions={true}
                                          filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                          isOptionEqualToValue={(option, value) => option.label === value.label}
                                          options={primaryContact_auto_complete_data}
                                          renderInput={(params) => (
                                            <>
                                              <MuiTextField
                                                {...params}
                                                size='small'
                                                label="Primary contact"
                                                required={true}
                                                onClick={(e) => form.blur('autoCompletes.primaryContact')}
                                                error={touched['autoCompletes.primaryContact'] && errors.autoCompletes?.primaryContact}
                                                helperText={touched['autoCompletes.primaryContact'] && errors.autoCompletes?.primaryContact}
                                              />
                                            </>
                                          )}
                                        />
                                      </Grid>

                                    }
                                  </Grid>
                                </Stack>

                                {/* response address */}
                                <Stack>
                                  <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Response address</Typography>
                                  <Grid container spacing={2}>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Address line 1" name="demandDetails.demandResponseAddress.street1" size='small' />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                      <TextField fullWidth label="Address line 2" name="demandDetails.demandResponseAddress.street2" size='small' />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                      <TextField fullWidth label="City" name="demandDetails.demandResponseAddress.city" size='small' />
                                    </Grid>
                                    <Grid xs={6} md={4}>
                                      <TextField fullWidth label="State" name="demandDetails.demandResponseAddress.state" select size='small'>
                                        {stateLabelValues.map((option) => (
                                          <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid xs={6} md={4}>
                                      <TextField fullWidth label="Zip" name="demandDetails.demandResponseAddress.zipCode" size='small' />
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </Stack>
                            </Grid>
                          )}

                          {tabValue === 1 && (
                            <Grid container spacing={2} sx={{ mb: 5 }}>
                              <Stack spacing={2} sx={{ mt: 3, mb: 5, width: '100%' }}>
                                <TextField fullWidth label="Select template" name='metadata.demandTemplateId' select size='small' required
                                  helperText={viewType === PRECEDENT_VIEW_TYPE && `Version ${currentTemplate?.version}`}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (!value) return;
                                    form.change('metadata.demandTemplateId', value);
                                    if (noTemplatedInitially && value !== NO_TEMPLATE) {
                                      setTemplateDialogState({ ...templateDialogState, confirmTemplateChange: { ...templateDialogState.confirmTemplateChange, open: true } });
                                    } else if (!noTemplatedInitially && value === NO_TEMPLATE) {
                                      setTemplateDialogState({ ...templateDialogState, confirmNoTemplate: { ...templateDialogState.confirmNoTemplate, open: true } });
                                    } else {
                                      setCurrentTemplate(
                                        availableTemplatesAndCustomFields.find((template) => template.templateId === value)
                                      );
                                    }
                                  }}
                                >
                                  {availableTemplatesAndCustomFields.map((option) => {
                                    return (
                                      <MenuItem key={option.templateId} value={option.templateId}>
                                        {option.templateName}
                                      </MenuItem>
                                    )
                                  })}
                                </TextField>

                                {shouldShowTemplateUpdateAlert && (
                                  <Box sx={{ mb: 1 }} >
                                    <TemplateUpdateAlert
                                      user={user}
                                      templateBody={templateBody}
                                      pinnedTemplateBody={templateBodyForPinnedVersion}
                                      documentMetaData={metadata}
                                      viewType={viewType}
                                    />
                                  </Box>
                                )}

                                <Dialog open={templateDialogState?.confirmNoTemplate?.open || templateDialogState?.confirmTemplateChange?.open} onClose={handleTemplateDialogClose}>
                                  <DialogContent>

                                    <Stack spacing={2}>
                                      <Typography variant='body1' color="primary">{templateDialogState?.confirmNoTemplate?.open ? `${templateDialogState.confirmNoTemplate.text1}` : `${templateDialogState.confirmTemplateChange.text1}`}
                                      </Typography>

                                      <Typography variant='body1' color="primary">
                                        {templateDialogState?.confirmNoTemplate?.open ? `${templateDialogState?.confirmNoTemplate?.text2}` : `${templateDialogState.confirmTemplateChange.text2}`}
                                      </Typography>
                                    </Stack>


                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleTemplateCancelChange} color="secondary">
                                      {templateDialogState?.confirmNoTemplate?.open ? `${templateDialogState.confirmNoTemplate.buttonText1}` : `${templateDialogState.confirmTemplateChange.buttonText1}`}
                                    </Button>
                                    <Button onClick={handleTemplateOkChange} variant="contained" color="secondary">
                                      {templateDialogState?.confirmNoTemplate?.open ? `${templateDialogState.confirmNoTemplate.buttonText2}` : `${templateDialogState.confirmTemplateChange.buttonText2}`}
                                    </Button>
                                  </DialogActions>
                                </Dialog>

                                {currentTemplate != null && currentTemplate.templateId !== NO_TEMPLATE &&
                                  <Divider flexItem style={{ width: '100%' }} sx={{ mt: 2 }} />
                                }

                                {currentTemplate != null && currentTemplate.templateId !== NO_TEMPLATE && customerDemandFields.map(({ friendlyName, fieldName, dataType, aiDataSourceConfig, aiGenerationPrompt }, index) => (
                                  currentTemplate.customFieldsList.includes(fieldName) && (<Stack key={fieldName} spacing={.5}>
                                    <Stack direction="row" justifyContent="space-between">
                                      <Stack direction="row" spacing={1} alignItems="center">
                                        {aiDataSourceConfig && aiGenerationPrompt !== '' && <AutoAwesome sx={{ color: deepPurple[900] }} fontSize='md' />}
                                        <Typography variant="body1" sx={{ mb: 1.5 }}>
                                          {friendlyName}
                                        </Typography>
                                      </Stack>
                                      {
                                        fieldsWithGeneratedNarrative[fieldName] && (
                                          <ReferenceFeedback
                                            fieldName={fieldName}
                                            fieldContent={values?.customDemandFieldsList[index]?.fieldValue}
                                            fieldContext={{ aiDataSourceConfig }}
                                          />
                                        )
                                      }
                                    </Stack>
                                    <Field name={`customDemandFieldsList[${index}]`}>
                                      {dataType === 'htmltext' &&
                                        (({ input }) => (
                                          <ConfiguredRichTextEditor
                                            content={input.value.fieldValue}
                                            onChange={debounce(({ editor }) => input.onChange({ fieldName, dataType, fieldValue: editor.getHTML() }), 1000)}
                                            showGenerateButton={aiDataSourceConfig && aiGenerationPrompt !== ''}
                                            disabled={metadata.documentStatus === 'AwaitingMedicalExtraction'}
                                            fieldName={fieldName}
                                            aiDataSourceConfig={aiDataSourceConfig}
                                            documentId={documentId}
                                            user={user}
                                          />
                                        ))}
                                    </Field>
                                    {aiReferencesForField[fieldName] && <AiReferenceTable tableData={aiReferencesForField[fieldName]} fieldName={fieldName} />}
                                  </Stack>)
                                ))}
                              </Stack>
                            </Grid>
                          )}
                        </Stack>

                        <ConfirmationDialog handleOk={async () => { setDialogState({ discardChanges: false }); closeEditDemandDetails(); }} {...confirmationDialogProps} />
                      </div>
                    )}
                  </>
                </ResizableWrapWithPdfViewer>
              </div>
            </form>
          );
        }}
      />
    </DialogContent>
  );
};

export default EditDemandDetails;


export const META_DATA_TEMPLATE_ID = {
    path: 'metadata.demandTemplateId',
    label: 'Demand template',
    required: true
}

export const SENDING_FIRM_CASE_NUMBER = {
    path: 'sendingFirm.firmCaseNumber',
    label: 'Matter number or case ID',
    required: true
}

export const CLAIM_INFO_CLAIM_NUMBER = {
    path: 'claimInfo.claimNumber',
    label: 'Claim number',
    required: false
}

export const CLAIM_INFO_CLAIMANT_FIRSTNAME = {
    path: 'claimInfo.claimant.firstName',
    label: 'Client',
    required: true
}

export const CLAIM_INFO_CLIENT_IS_INJURED_PARTY = {
    path: 'claimInfo.clientIsInjuredParty',
    label: 'Client is injured party',
    required: false
}

export const CLAIM_INFO_INJURED_PARTY = {
    path: 'claimInfo.injuredPartyFullName',
    label: 'Injured party',
    required: true
}

export const CLAIM_INFO_INSURED_FIRST_NAME = {
    path: 'claimInfo.insuredFirstName',
    label: 'Insured',
    required: true
}

export const CLAIM_INFO_CLIENT_IS_TORTFEASOR = {
    path: 'claimInfo.insuredIsTortfeasor',
    label: 'Insured is tortfeasor',
    required: false
}

export const CLAIM_INFO_TORTFEASOR = {
    path: 'claimInfo.tortfeasorFullName',
    label: 'Tortfeasor',
    required: true
}

export const CLAIM_INFO_DATE_OF_LOSS = {
    path: 'claimInfo.dateOfLoss',
    label: 'Date of loss',
    required: true
}

export const CLAIM_INFO_CLAIM_COVERAGE = {
    path: 'claimInfo.claimCoverage',
    label: 'Coverage',
    required: false
}

export const CLAIM_INFO_LOSS_STATE = {
    path: 'claimInfo.lossState',
    label: 'Loss state',
    required: true
}

export const CLAIM_INFO_LOSS_COUNTY = {
    path: 'claimInfo.lossCounty',
    label: 'Loss County',
    required: false
}

export const AUTOCOMPLETES_CARRIER = {
    path: 'autoCompletes.carrier',
    label: 'Carrier',
    required: true
}

export const RECIPIENT_CARRIER_FAX_NUMBER = {
    path: 'recipientCarrier.contactInfo.faxNumber',
    label: 'Carrier fax number',
    required: false
}

export const RECIPIENT_CARRIER_EMAIL_ADDRESS = {
    path: 'recipientCarrier.contactInfo.emailAddress',
    label: 'Carrier email',
    required: false
}

export const RECIPIENT_CARRIER_PHONE_NUMBER = {
    path: 'recipientCarrier.contactInfo.phoneNumber',
    label: 'Carrier phone',
    required: false
}

export const RECIPIENT_CARRIER_ADDRESS_1 = {
    path: 'recipientCarrier.contactInfo.address.street1',
    label: 'Address line 1',
    required: false
}

export const RECIPIENT_CARRIER_ADDRESS_2 = {
    path: 'recipientCarrier.contactInfo.address.street2',
    label: 'Address line 2',
    required: false
}

export const RECIPIENT_CARRIER_CITY = {
    path: 'recipientCarrier.contactInfo.address.city',
    label: 'City',
    required: false
}

export const RECIPIENT_CARRIER_STATE = {
    path: 'recipientCarrier.contactInfo.address.state',
    label: 'State',
    required: false
}

export const RECIPIENT_CARRIER_ZIP_CODE = {
    path: 'recipientCarrier.contactInfo.address.zipCode',
    label: 'Zip',
    required: false
}

export const RECIPIENT_ADJUSTER_FIRST_NAME = {
    path: 'recipientAdjuster.firstName',
    label: 'Adjuster first name',
    required: false
}

export const RECIPIENT_ADJUSTER_LAST_NAME = {
    path: 'recipientAdjuster.lastName',
    label: 'Adjuster last name',
    required: false
}

export const RECIPIENT_ADJUSTER_PHONE_NUMBER = {
    path: 'recipientAdjuster.contactInfo.phoneNumber',
    label: 'Adjuster phone',
    required: false
}

export const RECIPIENT_ADJUSTER_EMAIL_ADDRESS = {
    path: 'recipientAdjuster.contactInfo.emailAddress',
    label: 'Adjuster email',
    required: false
}

export const DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT = {
    path: 'demandDetails.displayDemandAmount',
    label: 'Display demand amount in letter',
    required: true
}

export const DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR = {
    path: 'demandDetails.policyLimitDemandIndicator',
    label: 'This is a policy limit demand',
    required: false
}

export const CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM = {
    path: 'claimInfo.insuredPolicyLimitsAsUnderstoodByFirm',
    label: 'Insured policy limit',
    required: false
}

export const DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE = {
    path: 'demandDetails.demandOfferToSettleAmount',
    label: 'Demand amount',
    required: false
}

export const DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT = {
    path: 'damagesSummary.demandDamagesAmount',
    label: 'Expected medical specials amount',
    required: false
}

export const SENDING_FIRM_TIN = {
    path: 'sendingFirm.firmTINNumber',
    label: 'Tax ID',
    required: false
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE = {
    path: 'demandDetails.demandResponseDueDate',
    label: 'Demand response fixed due date',
    required: false
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE = {
    path: 'demandDetails.demandResponseRelativeDueDate',
    label: 'Relative days to respond',
    required: false
}

export const DEMAND_DETAILS_USING_RELATIVE_DUE_DATE = {
    path: 'demandDetails.usingRelativeDueDate',
    label: 'Use relative due date',
    required: false
}

export const AUTOCOMPLETES_ATTORNEY = {
    path: 'autoCompletes.attorney',
    label: 'Attorney',
    required: true
}

export const AUTOCOMPLETES_COLLABORATORS = {
    path: 'autoCompletes.collaborators',
    label: 'Collaborators',
    required: false
}

export const AUTOCOMPLETES_PRIMARY_CONTACT = {
    path: 'autoCompletes.primaryContact',
    label: 'Primary contact',
    required: true
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_1 = {
    path: 'demandDetails.demandResponseAddress.street1',
    label: 'Address line 1',
    required: false
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_2 = {
    path: 'demandDetails.demandResponseAddress.street2',
    label: 'Address line 2',
    required: false
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_CITY = {
    path: 'demandDetails.demandResponseAddress.city',
    label: 'City',
    required: false
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_STATE = {
    path: 'demandDetails.demandResponseAddress.state',
    label: 'State',
    required: false
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_ZIP_CODE = {
    path: 'demandDetails.demandResponseAddress.zipCode',
    label: 'Zip',
    required: false
}

export const CUSTOMER_SPECIAL_NOTES = {
    path: 'customerSpecialNotes',
    label: 'Demand notes',
    required: false
}

export const MUTATORS_SHOW_CARRIER_CONTACT_INFO = {
    path: 'mutators.showCarrierContactInfo',
    label: 'Carrier contact info (optional)',
    required: false
}

export const MUTATORS_SHOW_ADJUSTER_CONTACT_INFO = {
    path: 'mutators.showAdjusterContactInfo',
    label: 'Adjuster contact info (optional)',
    required: false
}

export const MUTATORS_ATTORNEY_IS_PRIMARY_CONTACT = {
    path: 'mutators.attorneyIsPrimaryContact',
    label: 'Attorney is primary contact',
    required: false
}

export const requiredFieldsForTooltip = [
    META_DATA_TEMPLATE_ID,
    SENDING_FIRM_CASE_NUMBER,
    CLAIM_INFO_CLAIMANT_FIRSTNAME,
    CLAIM_INFO_INJURED_PARTY,
    CLAIM_INFO_INSURED_FIRST_NAME,
    CLAIM_INFO_TORTFEASOR,
    CLAIM_INFO_DATE_OF_LOSS,
    CLAIM_INFO_LOSS_STATE,
    AUTOCOMPLETES_CARRIER,
    AUTOCOMPLETES_ATTORNEY,
    AUTOCOMPLETES_PRIMARY_CONTACT,
]
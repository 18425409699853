import { FilterAlt, FilterAltOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setIcdCodesFilterModel } from '../redux/slices/icdCodesSlice';
import { createFilterId } from '../common';


const DiagnosesAlertActionButton = () => {
    const dispatch = useDispatch();
    const { icdCodesFilterModel } = useSelector((state) => state.IcdCodes);
    const filterTargets = [
        { field: 'status', operator: 'not', value: 'EXCLUDED' },
        { field: 'status', operator: 'not', value: 'INCLUDED' }
    ];

    const hasFilteredIcdCodesFromAlert = filterTargets.every(target =>
        icdCodesFilterModel.items.some(item =>
            target.field === item.field &&
            target.operator === item.operator &&
            target.value === item.value
        )
    );

    const handleFilterFromAlert = () => {
        if (hasFilteredIcdCodesFromAlert === false) {
            dispatch(setIcdCodesFilterModel({
                items: [
                    ...icdCodesFilterModel.items,
                    {
                        id: createFilterId(),
                        field: 'status',
                        operator: 'not',
                        value: 'EXCLUDED'
                    },
                    {
                        id: createFilterId(),
                        field: 'status',
                        operator: 'not',
                        value: 'INCLUDED'
                    }
                ]
            }));
        } else if (hasFilteredIcdCodesFromAlert === true) {

            const filterItemsLessAutoAlertFilter = icdCodesFilterModel.items.filter(
                item => !filterTargets.some(exclude =>
                    exclude.field === item.field &&
                    exclude.operator === item.operator &&
                    exclude.value === item.value
                )
            );

            dispatch(setIcdCodesFilterModel({
                items: [...filterItemsLessAutoAlertFilter]
            }))
        }
    }

    return (
        <Button
            color="inherit"
            variant={hasFilteredIcdCodesFromAlert ? "outlined" : "text"}
            startIcon={hasFilteredIcdCodesFromAlert ? <FilterAlt /> : <FilterAltOutlined />}
            size="small"
            onClick={handleFilterFromAlert}
        >
            {hasFilteredIcdCodesFromAlert ? "Remove filters" : "Filter ICD codes"}
        </Button>
    )
}

export default DiagnosesAlertActionButton
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import NoDeliveryIcon from '../CommonComponents/Chips/CustomIcons/NoDeliveryIcon'

const KeyValuePair = ({ label, value, value2, validation, callToAction, useKeyOptionalStyles, carrierName, demandIsDeliverable = true }) => {
    const keyDefaultStyle = { fontWeight: 500 }
    const keyOptionalStyles = { textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }
    const keyStyles = useKeyOptionalStyles ? { ...keyDefaultStyle, ...keyOptionalStyles } : keyDefaultStyle
    const valueStyles = { textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }

    return (
        <Stack direction="row">
            <Stack direction="row" spacing={1} sx={{ width: '45%' }} alignContent={'flex-start'}>
                <Typography variant="body2" sx={keyStyles}>
                    {label}
                </Typography>

            </Stack>
            <Box sx={{ width: '55%', display: 'flex', alignItems: 'center', gap: 1 }}>
                {!demandIsDeliverable &&

                    <Tooltip
                        title={`Demand will not be delivered to ${carrierName}`}
                        arrow
                        placement='top'
                    >
                        <Stack>
                            <NoDeliveryIcon color="info" />
                        </Stack>
                    </Tooltip>

                }
                <Tooltip title={value2 ? `${value} ${value2}` : value} arrow placement="top-start">
                    <Typography variant="body2" sx={valueStyles}>
                        {value} {value2}
                    </Typography>
                </Tooltip>
                {validation}
                {callToAction}
            </Box>

        </Stack>
    )
}

export default KeyValuePair
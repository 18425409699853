import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Typography, Box, Tooltip, Paper, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import DataGridWithExpandedDetails from '../../CommonComponents/DataGrid/DataGridWithExpandedDetails';
import AiReferencesPageDetailPanel from './AiReferencesPageDetailPanel';
import Title from '../../CommonComponents/Title';
import { formatPageNumbers } from '../DemandForms/Utilities';
import { copyToClipboard, formatDate } from '../../common';
import { setToast } from '../../redux/slices/globalToastSlice';
import { theme } from '../../Theme';

const initialState = {
  sorting: {
    sortModel: [{ field: 'page', sort: 'asc' }],
  },
};

const getSummaryAttributes = ({
  symptoms,
  lifestyle_impacts,
  diagnoses,
  treatments_recommended,
  treatments_performed,
  medical_professional,
}) => ({
  'Medical Professional': medical_professional?.value,
  'Symptoms': symptoms?.value,
  'Diagnoses': diagnoses?.value,
  'Treatments': treatments_performed?.value,
  'Treatments recommended': treatments_recommended?.value,
  'Lifestyle impacts': lifestyle_impacts?.value,
});

const AiReferencesTreatmentDetailPanel = ({ treatments }) => {
  const rows = treatments.map((treatment, index) => ({
    id: index + 1,
    pages: [...treatment.generated_page_number_list],
    ...treatment,
    summaryAttributes: getSummaryAttributes(treatment),
  }));

  const columns = [
    {
      field: 'pages',
      headerName: 'Pages',
      width: 200,
      editable: false,
      filterable: true,
      valueGetter: (params) => params.row.pages[0],
      renderCell: ({ row }) => {
        return <Typography variant="tableP1">{formatPageNumbers(row.pages)}</Typography>;
      },
    },
    {
      field: 'treatment_date',
      headerName: 'Date',
      width: 200,
      editable: false,
      filterable: true,
      renderCell: ({ row }) => {
        const unknownDate = isNaN(new Date(row?.treatment_date?.value));
        return (
          <>
            {unknownDate && (
              <Tooltip title="AI could not determine the name of this facility" placement="top">
                <WarningAmberOutlined color="warning" sx={{ mr: 1 }} />
              </Tooltip>
            )}
            <Typography variant="tableP1" sx={unknownDate ? { fontStyle: 'italic' } : {}}>
              {unknownDate ? 'Unknown date' : formatDate(row?.treatment_date?.value)}
            </Typography>
          </>
        );
      },
    },
    {
      field: 'summary',
      headerName: 'Summary',
      width: 150,
      editable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      resizable: false,
      renderCell: ({ row }) => {
        return (
          <Tooltip
            title={<SummaryTooltip treatmentDate={row?.treatment_date?.value} attributes={row.summaryAttributes} />}
            placement="right-start"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [-6, -16],
                    },
                  },
                ],
              },
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  fontWeight: 450,
                  bgcolor: 'transparent',
                },
              },
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <InfoOutlinedIcon />
              <Typography variant="tableP1">View</Typography>
            </Stack>
          </Tooltip>
        );
      },
    },
  ];

  const getDetailPanelContent = useCallback(({ row }) => <AiReferencesPageDetailPanel pages={row.pages} treatment={row} />, []);

  return (
    <DataGridWithExpandedDetails
      rows={rows}
      columns={columns}
      getDetailPanelContent={getDetailPanelContent}
      isDetailPanel
      initialState={initialState}
      density="compact"
    />
  );
};

const SummaryTooltip = ({ treatmentDate, attributes }) => {
  const dispatch = useDispatch();

  const unknownDate = treatmentDate === undefined;

  return (
    <Paper
      elevation={8}
      sx={{
        px: 2,
        py: 1,
        width: '600px',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          {unknownDate && (
            <Tooltip title="AI could not determine the date of this visit" arrow placement="top">
              <WarningAmberOutlined color="warning" />
            </Tooltip>
          )}
          <Title>Summary: {unknownDate ? 'Unknown date' : formatDate(treatmentDate)}</Title>
        </Stack>
        <IconButton
          size="small"
          onClick={() =>
            copyToClipboard(JSON.stringify(attributes, null, 4), () =>
              dispatch(setToast({ isOpen: true, message: 'Copied to clipboard!', severity: 'success' }))
            )
          }
        >
          <ContentCopyIcon />
        </IconButton>
      </Stack>
      
      <Stack spacing={1} sx={{ mt: 1 }}>
        {Object.entries(attributes).map(
          ([key, value]) =>
            key &&
            value && (
              <Box key={key}>
                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                  {key}
                </Typography>
                <Typography variant="body2">{value}</Typography>
              </Box>
            )
        )}
      </Stack>
    </Paper>
  );
};

export default AiReferencesTreatmentDetailPanel;

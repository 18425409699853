export const MAX_POLLING_RETRIES = 15;
export const COMPLETE_STATUS = 'complete';
export const FAILED_STATUS = 'failed';
export const POLLING_INTERVAL = 4000;
export const FETCH_PDF_DELAY = 100;

export const updateLoadingState = (state, action, isLoading) => {
    const documentId = action.meta.arg.documentId;
    state.demandS3RegenerationLoadingData = {
        ...state.demandS3RegenerationLoadingData,
        [documentId]: isLoading
    }

    state.demandS3RegenerationSkeletonData = {
        ...state.demandS3RegenerationSkeletonData,
        [documentId]: isLoading
    }
}
export const parseQueryStringToObject = (queryString) => {
  const queryParams = new URLSearchParams(queryString);
  const authSuccess = queryParams.get('authSuccess') && queryParams.get('authSuccess') === 'true';
  const integrationState =
    queryParams.get('integrationState') && queryParams.get('integrationState').replaceAll("'", '"');
  const integrationStateJson = JSON.parse(integrationState);

  return {
    ...(authSuccess && { authSuccess }),
    ...(integrationState && { integrationState: integrationStateJson }),
  };
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogTitle, DialogContent, DialogActions, Button, Stack, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { setToast } from '../redux/slices/globalToastSlice';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { v4 as uuidv4 } from 'uuid';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';
import {
  MRI_ENTITY_INSIGHTS_TYPE,
  MRI_URL_PATH,
  formatBodyPartName,
  INCLUDED_STATUS,
} from '../MedicalsComponents/insights';
import { getDocumentFileByPageNumber } from '../common-document';
import { useGetDocumentInsightsQuery, useAddEntityInsightDetailsMutation } from '../services/insights/insightsApi';
import { setMriFindingsUpdateLoading } from '../redux/slices/mriFindingsSlice';
import SelectPages from '../CommonComponents/Inputs/SelectPages';

const filter = createFilterOptions();

const AddMriFinding = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.GlobalDialogues.addMriFinding);
  const { documentId } = data;

  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId, { skip: !documentId });
  const { data: mriSummaryData = {} } = useGetDocumentInsightsQuery(
    {
      documentId,
      insightsType: MRI_URL_PATH,
    },
    { skip: !documentId }
  );

  const [createMriFinding] = useAddEntityInsightDetailsMutation({
    fixedCacheKey: 'update-mri-finding',
  });

  const [pages, setPages] = useState([]);
  const [bodyPart, setBodyPart] = useState('');
  const [description, setDescription] = useState('');

  const getBodyParts = (data) =>
    data?.insights?.narratives && Array.isArray(data.insights.narratives)
      ? data.insights.narratives.map((narrative) => narrative.body_part)
      : [];

  const isSaveDisabled =
    !description || description.length === 0 || !bodyPart || bodyPart.length === 0 || !pages || pages.length === 0;

  const handleSaveClick = () => {
    dispatch(setMriFindingsUpdateLoading(true));

    const newMriFindingEntities = pages.map((generatedPageNumber) => {
      const file = getDocumentFileByPageNumber(documentFiles, generatedPageNumber);

      // Calculate file page number
      const page_number = generatedPageNumber - file?.fileEntityData?.startPage + 1 || 1;

      return {
        entityInsightId: uuidv4(),
        entityInsightType: MRI_ENTITY_INSIGHTS_TYPE,
        fileEntityId: file?.fileEntityId,
        entityInsightData: {
          body_part: bodyPart,
          value: description,
          status: INCLUDED_STATUS,
          needs_review: false,
          bboxes: [],
          page_number,
        },
      };
    });

    createMriFinding({ documentId, newEntityInsights: newMriFindingEntities })
      .unwrap()
      .catch((error) => {
        console.log('error', error);
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with adding MRI finding' }));
        dispatch(setMriFindingsUpdateLoading(false));
      });

    handleClose();
  };

  const handleClose = () => dispatch(resetGlobalDialogues());

  return (
    <>
      <DialogTitle> Add MRI finding </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            autoFocus
            id="description"
            label="Description"
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            fullWidth
            multiline
            maxRows={4}
          />

          <Autocomplete
            id="select-body-parts"
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={getBodyParts(mriSummaryData)}
            getOptionLabel={(option) => {
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.label;
              }
              return formatBodyPartName(option);
            }}
            value={bodyPart}
            onChange={(e, newValue) =>
              newValue && newValue.inputValue ? setBodyPart(newValue.inputValue) : setBodyPart(newValue)
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `Add "${inputValue}"`,
                });
              }

              return filtered;
            }}
            renderInput={(params) => <TextField {...params} label="Body Part" />}
          />

          <SelectPages
            documentFiles={documentFiles}
            pages={pages}
            handleOnChange={(newValues) => setPages(newValues)}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>

        <Button disabled={isSaveDisabled} onClick={handleSaveClick} variant="contained" color="secondary">
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default AddMriFinding;

import React, { useEffect, useState, useMemo } from 'react'
import { Form } from 'react-final-form';
import { TextField, Autocomplete, Switches, Radios } from 'mui-rff'
import { Stack, Button, InputAdornment, IconButton, CircularProgress, DialogContent, DialogTitle, Grid, Typography, Divider, MenuItem, FormControl, Toolbar } from '@mui/material';
import { Close, DoNotDisturbOutlined, InfoOutlined, PersonOutlined } from '@mui/icons-material';
import Title from '../../CommonComponents/Title.jsx';
import { theme } from '../../Theme.js';
import ResizableWrapWithPdfViewer from '../../CommonComponents/ResizableWrapWithPdfViewer.jsx';
import ConfirmationDialog from "../../dialogs/ConfirmationDialog.js";
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber, formatDateForForm, formatDecimal, cleanNumber, getCurrentDate, userHasPermission } from '../../common.js';
import { EmailOutlined as EmailOutlinedIcon, PersonAddAlt1 as PersonAddAlt1Icon, DeleteForever as DeleteIcon, FaxOutlined as FaxOutlinedIcon } from '@mui/icons-material';
import { coverageList, stateLabelValues } from '../../common-data.js';
import { validateValue, errorDictionary } from '../../Validations/validationList.js';
import { parseNestedObject } from '../../helpers/formHelpers';
import { buildCarrierAliasOptionsList, matchCarrierByAlias, matchByAlias, splitName } from './FormHelpers.js';
import { DiscardWork } from '../../dialogs/DialogText.js';
import { setDemandEditOpen } from '../../redux/slices/demandDomSlice.js';
import { handleDemandEditingSave } from './Utilities';
import { useSaveDocumentDataMutation, useLazyGetDocumentDataQuery } from '../../services/documentData/documentDataApi';
import { getConfigValue } from '../../api';
import DemandResponseDueDateFields from './SharedFields/DemandResponseDateFields';
import { useParams } from 'react-router-dom';
import { PRECEDENT_VIEW_TYPE, LAW_FIRM_VIEW_TYPE } from '../../common-view-types';
import { isPrecedentUser } from '../../common-roles';
import { useGetPdfUrlQuery } from '../../services/pdf/pdfApi';
import { useFetchAuthorFormDataQuery } from '../../services/authFormData/authFormDataApi';

const EditDemand = ({ carriersData, isLoading, setIsSaving }) => {
    const { documentId } = useParams();
    const dispatch = useDispatch();
    const { user, userData } = useSelector((state) => state.User);

    const viewType = isPrecedentUser(userData) ? PRECEDENT_VIEW_TYPE : LAW_FIRM_VIEW_TYPE;

    const { customerData } = useSelector((state) => state.Customer);

    const { data: { demandS3Url } = {}, isLoading: isPdfLoading } = useGetPdfUrlQuery(documentId, { skip: !documentId, });
    const { demandS3RegenerationSkeletonData, demandS3RegenerationLoadingData } = useSelector((state) => state.PdfCustomSlice);
    const isPdfRegnerationLoading = Boolean(demandS3RegenerationLoadingData[documentId] || demandS3RegenerationSkeletonData[documentId]);

    const { data: authorFormData = {} } = useFetchAuthorFormDataQuery({ ...(viewType === PRECEDENT_VIEW_TYPE && { customerId: customerData?.customerId }) });
    const { users = [], approvers = [] } = authorFormData;

    const [triggerGetDocumentData, { data: DocumentDataFetchResponseDictionary, isLoading: isDocumentLoading }] = useLazyGetDocumentDataQuery();
    const { document, metadata, deliveryAttorneyEmail, deliveryAttorneyName, deliveryAttorneyPhoneNumber } = DocumentDataFetchResponseDictionary || {};
    const [saveDocumentData, { isLoading: submitting, isSuccess: isSaveSuccessful, isError: hasSaveError, error: saveError }] = useSaveDocumentDataMutation();

    const joinNames = nameObject => `${nameObject?.firstName ? nameObject?.firstName : ''}${nameObject?.lastName ? ' ' + nameObject?.lastName : ''}`

    const [dialogState, setDialogState] = useState({ confirmation: false, discardChanges: false });
    const [defaultRelativeDueDate, setDefaultRelativeDueDate] = useState(30);

    const approverIds = approvers?.map(approver => approver.userId) || [];
    const attorneys = users?.filter(user => approverIds?.includes(user.userId)) || [];
    const userNames = users?.map(user => joinNames(user)) || [];
    const caseManagers = document?.sendingFirm?.caseManagers.length > 0 ? document?.sendingFirm?.caseManagers : [{ firstName: '', lastName: '', contactInfo: { emailAddress: '', phoneNumber: '' } }]
    const caseManagerNames = caseManagers.map(manager => joinNames(manager)) || [''];


    useEffect(() => {
        //onLoad
        getConfigValue('defaultRelativeDueDate', null, user)
            .then((response) => setDefaultRelativeDueDate(response ? response : 30))

        triggerGetDocumentData({ documentId, pollPdfStatus: false });
        //we don't need to trigger poll b/c trigger invalidates the cache and calls the query again which polling default set to true
    }, []);

    const carriersAliasesList = buildCarrierAliasOptionsList(carriersData);
    const isAttorneyMatch = (value) => attorneys.find(attorney => joinNames(attorney) === value);
    const isAttorneyPrimaryContactMatch = (value) => users.find(user => joinNames(user) === value);
    const isCaseManagerMatch = (value) => users.find(user => joinNames(user) === value);

    const onSubmit = async (values,) => {
        const saveBody = parseNestedObject({
            ...values,
            claimInfo: {
                ...values.claimInfo,
                insuredPolicyLimitsAsUnderstoodByFirm: cleanNumber(values.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm)
            },
            damagesSummary: {
                ...values.damagesSummary,
                demandDamagesAmount: cleanNumber(values.damagesSummary.demandDamagesAmount)
            },
            demandDetails: {
                ...values.demandDetails,
                demandOfferToSettleAmount: cleanNumber(values.demandDetails.demandOfferToSettleAmount),
                displayDemandAmount: !values.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm || values.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm == 0 ? false : true,
            }
        });

        delete saveBody.mutators;

        handleDemandEditingSave({
            data: saveBody,
            callbacks: { handleClose: () => dispatch(setDemandEditOpen(false)) },
            setIsSaving,
            dispatch,
            saveDocumentData,
            triggerGetDocumentData,
            documentId
        });
    }

    const mapDataToInitialValues = (documentData) => {
        const isUsingRelativeDueDate = typeof documentData?.demandDetails?.usingRelativeDueDate === 'boolean' ? documentData?.demandDetails?.usingRelativeDueDate : false;

        return {
            ...documentData,
            claimInfo: {
                ...documentData?.claimInfo,
                dateOfLoss: formatDateForForm(documentData?.claimInfo?.dateOfLoss)
            },
            demandDetails: {
                ...documentData?.demandDetails,
                demandWrittenDate: formatDateForForm(documentData?.demandDetails?.demandWrittenDate),
                demandResponseDueDate: formatDateForForm(documentData?.demandDetails?.demandResponseDueDate),
                demandResponseRelativeDueDate: isUsingRelativeDueDate ? (documentData?.demandDetails?.demandResponseRelativeDueDate || defaultRelativeDueDate) : '',
                usingRelativeDueDate: documentData?.demandDetails?.usingRelativeDueDate || false,
                policyLimitDemandIndicator: documentData?.demandDetails?.policyLimitDemandIndicator === undefined ? false : documentData?.demandDetails?.policyLimitDemandIndicator,
                demandOfferToSettleAmount: documentData?.demandDetails?.policyLimitDemandIndicator ? documentData?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm : documentData?.demandDetails?.demandOfferToSettleAmount,
            },
            sendingFirm: {
                ...documentData?.sendingFirm,
                caseManagers
            },
            mutators: {  // custom mutators when fields don't match schema 1:1
                attorney: joinNames(documentData?.sendingFirm?.attorney),
                primaryContact: joinNames(documentData?.sendingFirm?.primaryContact),
                caseManagers: caseManagerNames
            }
        }
    }
    const initialValues = useMemo(() => {
        if (!document || Object.keys(customerData ?? {}).length === 0) return {};
        return mapDataToInitialValues(document);
    }, [document, customerData, defaultRelativeDueDate]);

    const [valid, setValid] = useState(true);
    const [dirty, setDirty] = useState(false);

    const handleCloseEditView = () => dirty ? setDialogState({ discardChanges: true }) : dispatch(setDemandEditOpen(false));

    return (

        <DialogContent sx={{ marginTop: '0px', paddingTop: '0px', overflow: 'visible', paddingLeft: '0px', paddingRight: '0px' }}>

            <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>

                        <IconButton onClick={e => handleCloseEditView()} aria-label="close" sx={{ mr: 2, '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}>
                            <Close color={'white'} />
                        </IconButton>

                        <Title color={theme.palette.white.main}>Edit demand</Title>

                    </Stack>
                    <Button
                        type='submit'
                        form="editDemand"
                        disabled={isLoading || !valid || submitting || !dirty}
                        variant="contained"
                        color="white"
                        sx={{
                            '&:disabled': {
                                backgroundColor: ("rgba(255,255,255,0.2)")
                            }
                        }}
                        startIcon={(isLoading || submitting) && <CircularProgress size={20} color="inherit" />} // Show spinner when loading
                    >
                        {isLoading ? 'Saving...' : 'Save'}
                    </Button>
                </Stack>
            </DialogTitle>
            <Toolbar sx={{ height: '60px' }} />

            <Form
                validate={(values) => validate({ values, userData })}
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine, values, valid, errors, dirty, fields, dirtyFields, dirtySinceLastSubmit }) => {
                    setValid(valid);
                    setDirty(dirty);
                    const handleCollaboratorDelete = (index) => {
                        const newCaseManagers = values.sendingFirm.caseManagers.filter((manager, i) => i !== index)
                        form.change('sendingFirm.caseManagers', newCaseManagers)
                        form.change('mutators.caseManagers', newCaseManagers.map(manager => joinNames(manager)))
                    }

                    const handleCollaboratorAdd = () => {
                        const newCaseManagers = [...values.sendingFirm.caseManagers, { firstName: '', lastName: '', contactInfo: { emailAddress: '', phoneNumber: '' } }]
                        form.change('sendingFirm.caseManagers', newCaseManagers)
                        form.change('mutators.caseManagers', newCaseManagers.map(manager => joinNames(manager)))
                    }

                    const handleCarrierChanged = (newCarrier) => {

                        if (values.defaultDeliveryInformationEnabled) {
                            if (newCarrier) {
                                configureDefaultCarrierChannelValues(newCarrier);
                            }
                            else {
                                form.change("recipientCarrier.contactInfo.faxNumber", undefined);
                                form.change("recipientCarrier.contactInfo.emailAddress", undefined);
                                form.change("defaultDeliveryInformationEnabled", false);
                            }
                        }
                    };

                    const handleDefaultDeliveryEnableChanged = (e) => {
                        if (e.target.checked) {
                            const carrierAlias = carriersAliasesList.find((carrierAlias) => carrierAlias.aliasId === values.recipientCarrier?.carrierCustomerAliasId);
                            if (carrierAlias) {
                                configureDefaultCarrierChannelValues(carrierAlias.carrier);
                            }
                        }
                    }

                    const configureDefaultCarrierChannelValues = (carrier) => {
                        const faxChannel = carrier.deliveryChannels.find((channel) => channel.deliveryChannelType === "fax");
                        const emailChannel = carrier.deliveryChannels.find((channel) => channel.deliveryChannelType === "email");

                        if (emailChannel) {
                            form.change("recipientCarrier.contactInfo.emailAddress", emailChannel.deliveryDestinationAddress);
                        }
                        else {
                            form.change("recipientCarrier.contactInfo.emailAddress", undefined);
                        }

                        if (faxChannel) {
                            form.change("recipientCarrier.contactInfo.faxNumber", faxChannel.deliveryDestinationAddress);
                        }
                        else {
                            form.change("recipientCarrier.contactInfo.faxNumber", undefined);
                        }
                    };

                    const carrierName = values?.recipientCarrier?.carrierCommonName;
                    const carrierInfo = matchCarrierByAlias(carrierName, carriersData);
                    const deliveryChannels = carrierInfo?.deliveryChannels;
                    const defaultDeliveryChannelType = carrierInfo?.defaultDeliveryChannelType;

                    const emailChannel = deliveryChannels?.find(channel => channel.deliveryChannelType === 'email');
                    const emailDestinationAddress = emailChannel?.deliveryDestinationAddress;

                    const formCarrierEmail = values?.recipientCarrier?.contactInfo?.emailAddress;
                    const formCarrierFax = values?.recipientCarrier?.contactInfo?.faxNumber;
                    const faxChannel = deliveryChannels?.find(channel => channel.deliveryChannelType === 'fax');
                    const faxDestinationAddress = faxChannel?.deliveryDestinationAddress;
                    const doEmailsMatch = formCarrierEmail === emailDestinationAddress;
                    const doFaxesMatch = formCarrierFax === faxDestinationAddress;
                    const doesDefaultCarrierMatch = () => (defaultDeliveryChannelType === 'email' && doEmailsMatch) || (defaultDeliveryChannelType === 'fax' && doFaxesMatch)
                        || defaultDeliveryChannelType === 'manual' || defaultDeliveryChannelType === 'none';

                    return (
                        <form id="editDemand" onSubmit={handleSubmit}>
                            <Stack spacing={2}
                                sx={{
                                    height: 'calc(100vh - 80px)',
                                    overflow: 'scroll'
                                }}>
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    height: '100%',
                                    flexShrink: 0,
                                }}>
                                    <ResizableWrapWithPdfViewer
                                        defaultWidth={'60%'}
                                        pdfHeight={'84vh'}
                                        user={user}
                                        documentId={documentId}
                                    >
                                        <Stack spacing={2}
                                            sx={{
                                                height: 'calc(100vh - 80px)',
                                                overflow: 'scroll'
                                            }}>
                                            <Grid container >
                                                <Grid sx={{ mr: 2, mt: 2 }} container xs={12}>
                                                    <Grid xs={12}>
                                                        <Stack spacing={0} sx={{ mb: 1.5 }}>
                                                            <Title>Case information</Title>
                                                        </Stack>
                                                        <Stack spacing={2}>
                                                            <Stack direction={"row"} spacing={2}>
                                                                <TextField fullWidth label="Matter number or Case ID" name='sendingFirm.firmCaseNumber' required />
                                                                <TextField fullWidth label="Claim number" name='claimInfo.claimNumber' />
                                                            </Stack>

                                                            <Stack direction={"row"} spacing={2}>
                                                                <TextField fullWidth required label="Client" name='claimInfo.claimant.firstName'
                                                                    onChange={(e) => {
                                                                        form.change('claimInfo.claimant.firstName', e.target.value)

                                                                        if (values.claimInfo?.clientIsInjuredParty) {
                                                                            form.change('claimInfo.injuredPartyFullName', e.target.value);
                                                                        }
                                                                    }}
                                                                />
                                                            </Stack>

                                                            <Stack direction={"row"} spacing={2}>
                                                                <Switches
                                                                    name="claimInfo.clientIsInjuredParty"
                                                                    onChange={(e) => {
                                                                        const checked = e.target.checked;
                                                                        form.change('claimInfo.clientIsInjuredParty', checked)
                                                                        if (checked) {
                                                                            form.change('claimInfo.injuredPartyFullName', values?.claimInfo?.claimant?.firstName)
                                                                        } else {
                                                                            form.change('claimInfo.injuredPartyFullName', '')
                                                                        }
                                                                    }}
                                                                    data={{ label: 'Client is injured party', value: true }}
                                                                    color="secondary"
                                                                />
                                                            </Stack>
                                                            {!values.claimInfo?.clientIsInjuredParty &&
                                                                <Stack direction={"row"} spacing={2}>
                                                                    <TextField fullWidth required label="Injured party" name='claimInfo.injuredPartyFullName' size='small' />
                                                                </Stack>
                                                            }

                                                            <Stack direction={"row"} spacing={2}><TextField fullWidth label="Law firm" name='sendingFirm.firmName' /></Stack>

                                                            <Title>Case team</Title>

                                                            <Stack direction={"row"} spacing={2}>
                                                                <Autocomplete
                                                                    freeSolo
                                                                    options={approvers.map(option => joinNames(option))}
                                                                    name="mutators.attorney"
                                                                    label="Attorney"
                                                                    fullWidth
                                                                    onInputChange={(e, value) => {
                                                                        const { firstName, lastName } = splitName(value);
                                                                        const attorneyMatch = isAttorneyMatch(value);
                                                                        form.change('mutators.attorney', value)
                                                                        form.change('sendingFirm.attorney.firstName', firstName)
                                                                        form.change('sendingFirm.attorney.lastName', lastName)
                                                                        const newPhone = attorneyMatch?.phone
                                                                        const newEmail = attorneyMatch?.emailAddress
                                                                        newPhone && form.change('sendingFirm.attorney.contactInfo.phoneNumber', newPhone)
                                                                        newEmail && form.change('sendingFirm.attorney.contactInfo.emailAddress', newEmail)
                                                                    }}
                                                                />
                                                                <TextField fullWidth label="Attorney email" name='sendingFirm.attorney.contactInfo.emailAddress' type='email' disabled={isAttorneyMatch(values?.mutators?.attorney)} error={errors['sendingFirm.attorney.contactInfo.emailAddress']} helperText={errors['sendingFirm.attorney.contactInfo.emailAddress']} />

                                                                <TextField fullWidth label="Attorney phone" name='sendingFirm.attorney.contactInfo.phoneNumber' type='tel' disabled={isAttorneyMatch(values?.mutators?.attorney)}
                                                                    onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)} error={errors['sendingFirm.attorney.contactInfo.phoneNumber']} helperText={errors['sendingFirm.attorney.contactInfo.phoneNumber']}
                                                                />
                                                            </Stack>
                                                            <Stack direction={"row"} spacing={2}>
                                                                <Autocomplete
                                                                    freeSolo
                                                                    options={userNames}
                                                                    name="mutators.primaryContact"
                                                                    label="Primary contact"
                                                                    fullWidth
                                                                    onInputChange={(e, value) => {
                                                                        const { firstName, lastName } = splitName(value);
                                                                        const primaryContactMatch = isAttorneyPrimaryContactMatch(value);
                                                                        form.change('mutators.primaryContact', value)
                                                                        form.change('sendingFirm.primaryContact.firstName', firstName)
                                                                        form.change('sendingFirm.primaryContact.lastName', lastName)
                                                                        const newPhone = primaryContactMatch?.phone
                                                                        const newEmail = primaryContactMatch?.emailAddress
                                                                        newPhone && form.change('sendingFirm.primaryContact.contactInfo.phoneNumber', newPhone)
                                                                        newEmail && form.change('sendingFirm.primaryContact.contactInfo.emailAddress', newEmail)
                                                                    }}
                                                                />
                                                                <TextField fullWidth label="Primary contact email" name='sendingFirm.primaryContact.contactInfo.emailAddress' type='email' disabled={isAttorneyPrimaryContactMatch(values?.mutators?.primaryContact)}
                                                                    error={errors['sendingFirm.primaryContact.contactInfo.emailAddress']} helperText={errors['sendingFirm.primaryContact.contactInfo.emailAddress']}
                                                                />
                                                                <TextField fullWidth label="Primary contact phone" name='sendingFirm.primaryContact.contactInfo.phoneNumber' type='tel' disabled={isAttorneyPrimaryContactMatch(values?.mutators?.primaryContact)}
                                                                    onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)} error={errors['sendingFirm.primaryContact.contactInfo.phoneNumber']} helperText={errors['sendingFirm.primaryContact.contactInfo.phoneNumber']}
                                                                />
                                                            </Stack>

                                                            {values?.sendingFirm?.caseManagers?.map((manager, index) => {
                                                                return (
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <Autocomplete
                                                                            freeSolo
                                                                            options={userNames}
                                                                            name={`mutators.caseManagers[${index}]`}
                                                                            label='Collaborator'
                                                                            fullWidth
                                                                            onInputChange={(e, value) => {
                                                                                const { firstName, lastName } = splitName(value);
                                                                                const caseManagerMatch = isCaseManagerMatch(value);
                                                                                form.change(`mutators.caseManagers[${index}]`, value)
                                                                                form.change(`sendingFirm.caseManagers[${index}].firstName`, firstName)
                                                                                form.change(`sendingFirm.caseManagers[${index}].lastName`, lastName)
                                                                                const newEmail = caseManagerMatch?.emailAddress
                                                                                newEmail && form.change(`sendingFirm.caseManagers[${index}].contactInfo.emailAddress`, newEmail)
                                                                            }}
                                                                        />
                                                                        <TextField fullWidth label={`Collaborator email`} name={`sendingFirm.caseManagers[${index}].contactInfo.emailAddress`} type='email' disabled={isCaseManagerMatch(values?.mutators?.caseManagers[index])}
                                                                            error={errors[`sendingFirm.caseManagers[${index}].contactInfo.emailAddress`]} helperText={errors[`sendingFirm.caseManagers[${index}].contactInfo.emailAddress`]}
                                                                        />
                                                                        <IconButton onClick={() => handleCollaboratorDelete(index)} aria-label="delete" sx={{ mt: 2 }}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Stack>
                                                                )
                                                            }
                                                            )}
                                                            <Button
                                                                variant="outlined"
                                                                sx={{ border: 'none', color: '#2196F3', justifyContent: 'flex-start' }}
                                                                startIcon={<PersonAddAlt1Icon />}
                                                                onClick={handleCollaboratorAdd}
                                                            >
                                                                Add Collaborator
                                                            </Button>
                                                            <Grid sx={{ mr: 2 }} container xs={12}>
                                                                <Grid xs={12}>
                                                                    <Stack spacing={0} sx={{ mb: 1.5 }}>
                                                                        <Title>Demand details</Title>
                                                                    </Stack>

                                                                    <Stack spacing={2} sx={{}}>
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <TextField fullWidth label="Demand creation date"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    max: getCurrentDate()
                                                                                }}
                                                                                type='date'
                                                                                name='demandDetails.demandWrittenDate'
                                                                            />
                                                                        </Stack>
                                                                        <Stack direction={"row"} sx={{ ml: -50 }}>
                                                                            <DemandResponseDueDateFields
                                                                                form={form}
                                                                                values={values}
                                                                                initialValues={initialValues}
                                                                                errors={errors}
                                                                                isPdfRegnerationLoading={isPdfRegnerationLoading}
                                                                                gridPadding={0}
                                                                                topMargin='-10px'
                                                                                defaultRelativeDueDate={defaultRelativeDueDate}
                                                                            />
                                                                        </Stack>

                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <TextField
                                                                                label="Insured policy limit"
                                                                                name='claimInfo.insuredPolicyLimitsAsUnderstoodByFirm'
                                                                                onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                                                                onChange={(e) => {
                                                                                    if (values?.demandDetails?.policyLimitDemandIndicator) {
                                                                                        form.change("demandDetails.demandOfferToSettleAmount", e.target.value)
                                                                                    }
                                                                                    form.change("claimInfo.insuredPolicyLimitsAsUnderstoodByFirm", e.target.value)
                                                                                }}
                                                                            />

                                                                            <FormControl fullWidth>
                                                                                <Radios
                                                                                    color='secondary'
                                                                                    sx={{ width: '100%' }}
                                                                                    label="Is this a policy limit demand?"
                                                                                    name="demandDetails.policyLimitDemandIndicator"
                                                                                    onChange={(e) => {
                                                                                        form.change("demandDetails.policyLimitDemandIndicator", e.target.value === "true")
                                                                                        if (e.target.value === "true") {
                                                                                            form.change("demandDetails.demandOfferToSettleAmount", values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm)
                                                                                        } else {
                                                                                            form.change("demandDetails.demandOfferToSettleAmount", '')
                                                                                        }
                                                                                    }}
                                                                                    data={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                                                                                    radioGroupProps={{ row: true }}
                                                                                />
                                                                            </FormControl>
                                                                        </Stack>
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Expected medical specials amount"
                                                                                name='damagesSummary.demandDamagesAmount'
                                                                                onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }} />
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Demand amount"
                                                                                name='demandDetails.demandOfferToSettleAmount'
                                                                                onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                                                                disabled={values?.demandDetails?.policyLimitDemandIndicator}
                                                                            />
                                                                        </Stack>
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>

                                                            <Divider sx={{ mb: 1 }} />

                                                            <Grid sx={{ mr: 2 }} container xs={12}>
                                                                <Grid xs={12}>
                                                                    <Stack spacing={0} sx={{ mb: 1.5 }}>
                                                                        <Title>Loss details</Title>
                                                                    </Stack>
                                                                    <Stack spacing={2} sx={{ mb: 1 }}>
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <TextField fullWidth label="Loss state" name='claimInfo.lossState' select>
                                                                                {stateLabelValues.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                            <TextField fullWidth label="Loss county" name='claimInfo.lossCounty' />
                                                                        </Stack>
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <TextField fullWidth label="Date of loss" name='claimInfo.dateOfLoss'
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                type='date'
                                                                                inputProps={{
                                                                                    max: getCurrentDate()
                                                                                }}
                                                                            />
                                                                            <TextField fullWidth label="Coverage" name='claimInfo.claimCoverage' select>
                                                                                {coverageList.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                        </Stack>
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <TextField fullWidth label="Insured first name" name='claimInfo.insuredFirstName' />
                                                                            <TextField fullWidth label="Insured last name" name='claimInfo.insuredLastName' />
                                                                        </Stack>
                                                                        <Divider sx={{ mt: 2, mb: 1 }} />

                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid sx={{ mr: 2 }} container xs={12}>
                                                                <Grid xs={12}>
                                                                    <Stack spacing={0} sx={{ mb: 1.5 }}>
                                                                        <Title>Carrier information</Title>
                                                                    </Stack>
                                                                    <Stack spacing={2} sx={{ mb: 2 }}>
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <Autocomplete
                                                                                freeSolo
                                                                                options={carriersAliasesList}
                                                                                name='recipientCarrier.carrierCommonName'
                                                                                label="Carrier"
                                                                                fullWidth
                                                                                required
                                                                                onChange={(event, selection) => {
                                                                                    // This is trigger when drop down value is selected.
                                                                                    if (selection) {
                                                                                        const { value } = selection;
                                                                                        const alias = value.aliases.find(alias => !!alias.isCarrierNameAlias);
                                                                                        const aliasId = alias?.aliasId || '';
                                                                                        form.change('recipientCarrier.carrierCommonName', value.name);
                                                                                        form.change('recipientCarrier.carrierCustomerAliasId', aliasId);
                                                                                        handleCarrierChanged(value);
                                                                                    }
                                                                                }}

                                                                                onInputChange={(e, value) => {
                                                                                    // This is triggered when text box is typed into
                                                                                    form.change('recipientCarrier.carrierCommonName', value);

                                                                                    const matchedCarrier = matchCarrierByAlias(value, carriersData);
                                                                                    if (matchedCarrier) {
                                                                                        form.change('recipientCarrier.carrierCustomerAliasId', matchByAlias(value, matchedCarrier).aliasId);
                                                                                    }
                                                                                    else {
                                                                                        form.change('recipientCarrier.carrierCustomerAliasId', undefined);
                                                                                    }
                                                                                    handleCarrierChanged(matchedCarrier);

                                                                                }}
                                                                            />
                                                                        </Stack>
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <TextField fullWidth label="Adjuster first name" name='recipientAdjuster.firstName' />
                                                                            <TextField fullWidth label="Adjuster last name" name='recipientAdjuster.lastName' />
                                                                        </Stack>
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <TextField fullWidth label="Adjuster phone" name='recipientAdjuster.contactInfo.phoneNumber' type='tel'
                                                                                onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)} error={errors['recipientAdjuster.contactInfo.phoneNumber']} helperText={errors['recipientAdjuster.contactInfo.phoneNumber']}
                                                                            />
                                                                            <TextField fullWidth label="Adjuster email" name='recipientAdjuster.contactInfo.emailAddress' type='email' error={errors['recipientAdjuster.contactInfo.emailAddress']} helperText={errors['recipientAdjuster.contactInfo.emailAddress']} />
                                                                        </Stack>
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <Switches
                                                                                color='secondary'
                                                                                name="defaultDeliveryInformationEnabled"
                                                                                onChangeCapture={handleDefaultDeliveryEnableChanged}
                                                                                data={{ label: 'Use default delivery information', checked: document?.defaultDeliveryInformationEnabled }}
                                                                            />
                                                                        </Stack>
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <TextField fullWidth disabled={values.defaultDeliveryInformationEnabled} label="Carrier email" name='recipientCarrier.contactInfo.emailAddress' type='email' error={errors['recipientCarrier.contactInfo.emailAddress']} helperText={errors['recipientCarrier.contactInfo.emailAddress']} />
                                                                            <TextField fullWidth disabled={values.defaultDeliveryInformationEnabled} label="Carrier fax" name='recipientCarrier.contactInfo.faxNumber' type='tel'
                                                                                onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)} error={errors['recipientCarrier.contactInfo.faxNumber']} helperText={errors['recipientCarrier.contactInfo.faxNumber']} />
                                                                        </Stack>


                                                                        {doesDefaultCarrierMatch() && (<>
                                                                            <Stack direction={"row"} spacing={2}>
                                                                                <Typography variant="body1">{'Default delivery'}</Typography>
                                                                            </Stack>


                                                                            <Stack direction={"row"} spacing={2}>
                                                                                {defaultDeliveryChannelType === 'email' && <Stack direction={"row"} spacing={2}>
                                                                                    <EmailOutlinedIcon color="primary" />
                                                                                    <Typography variant="body1">{'Email'}</Typography>
                                                                                </Stack>}
                                                                                {defaultDeliveryChannelType === 'fax' && <Stack direction={"row"} spacing={2}>
                                                                                    <FaxOutlinedIcon color="primary" />
                                                                                    <Typography variant="body1">{'Fax'}</Typography>
                                                                                </Stack>}
                                                                                {defaultDeliveryChannelType === 'manual' && <Stack direction={"row"} spacing={2}>
                                                                                    <PersonOutlined color="primary" />
                                                                                    <Typography variant="body1">{'Manual'}</Typography>
                                                                                </Stack>}
                                                                                {defaultDeliveryChannelType === 'none' && <Stack direction={"row"} spacing={2}>
                                                                                    <DoNotDisturbOutlined color="primary" />
                                                                                    <Typography variant="body1">{'None'}</Typography>
                                                                                </Stack>}
                                                                            </Stack>
                                                                        </>)}


                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Stack>

                                    </ResizableWrapWithPdfViewer>
                                </div>
                            </Stack>

                            <ConfirmationDialog
                                dialogOpen={dialogState.discardChanges}
                                dialogText={DiscardWork.text}
                                cancelButtonText={DiscardWork.cancelText}
                                okButtonText={DiscardWork.confirmText}
                                handleCancel={() => { setDialogState({ discardChanges: false }) }}
                                handleOk={() => dispatch(setDemandEditOpen(false))}
                            />
                        </form>
                    )
                }}
            />
        </DialogContent>
    )
}

export default EditDemand;

const validate = (values) => {
    const errors = {};
    if (values?.sendingFirm?.attorney?.contactInfo?.emailAddress && !validateValue('email', values?.sendingFirm?.attorney?.contactInfo?.emailAddress)) {
        errors['sendingFirm.attorney.contactInfo.emailAddress'] = errorDictionary.email; //
    }
    // phone too
    if (values?.sendingFirm?.attorney?.contactInfo?.phoneNumber && !validateValue('phonenumber', values?.sendingFirm?.attorney?.contactInfo?.phoneNumber)) {
        errors['sendingFirm.attorney.contactInfo.phoneNumber'] = errorDictionary.phonenumber;
    }
    if (values?.sendingFirm?.primaryContact?.contactInfo?.emailAddress && !validateValue('email', values?.sendingFirm?.primaryContact?.contactInfo?.emailAddress)) {
        errors['sendingFirm.primaryContact.contactInfo.emailAddress'] = errorDictionary.email;
    }
    if (values?.sendingFirm?.primaryContact?.contactInfo?.phoneNumber && !validateValue('phonenumber', values?.sendingFirm?.primaryContact?.contactInfo?.phoneNumber)) {
        errors['sendingFirm.primaryContact.contactInfo.phoneNumber'] = errorDictionary.phonenumber;
    }
    if (values?.sendingFirm?.caseManagers) {
        values?.sendingFirm?.caseManagers.forEach((manager, index) => {
            if (manager?.contactInfo?.emailAddress && !validateValue('email', manager?.contactInfo?.emailAddress)) {
                errors[`sendingFirm.caseManagers[${index}].contactInfo.emailAddress`] = errorDictionary.email;
            }
        })
    }
    if (values?.recipientCarrier?.contactInfo?.emailAddress && !validateValue('email', values?.recipientCarrier?.contactInfo?.emailAddress)) {
        errors['recipientCarrier.contactInfo.emailAddress'] = errorDictionary.email;
    }
    if (values?.recipientAdjuster?.contactInfo && values?.recipientAdjuster?.contactInfo?.emailAddress && !validateValue('email', values?.recipientAdjuster?.contactInfo?.emailAddress)) {
        errors['recipientAdjuster.contactInfo.emailAddress'] = errorDictionary.email;
    }
    if (values?.recipientAdjuster?.contactInfo && values?.recipientAdjuster?.contactInfo?.phoneNumber && !validateValue('phonenumber', values?.recipientAdjuster?.contactInfo?.phoneNumber)) {
        errors['recipientAdjuster.contactInfo.phoneNumber'] = errorDictionary.phonenumber;
    }
    if (values?.recipientCarrier?.contactInfo?.faxNumber && !validateValue('phonenumber', values?.recipientCarrier?.contactInfo?.faxNumber)) {
        errors['recipientCarrier.contactInfo.faxNumber'] = errorDictionary.faxnumber;
    }
    return errors;
}
// Document Status
export const DOCUMENT_APPROVED = "DocumentApproved";
export const DOCUMENT_ARCHIVED = "DocumentArchived";
export const WAITING_FIRM_APPROVAL = "WaitingFirmApproval";
export const AWAITING_FIRM_REVIEW ="AwaitingFirmReview";
export const REJECTED_BY_LAW_FIRM = "RejectedByLawFirm";
export const AWAITNG_PRECEDENT_SIGNOFF = "AwaitingPrecedentSignoff";
export const AWAITING_MEDICAL_EXTRACTION = "AwaitingMedicalExtraction";
export const DOCUMENT_SUBMITTED = "DocumentSubmitted";
export const DOCUMENT_UPLOADED = "DocumentUploaded";
export const DOCUMENT_RECEIVED = "DocumentReceived";
export const HANDLING_COMPLETED = "HandlingCompleted";

// Document Type
export const TRADITIONALDEMAND = "TRADITIONALDEMAND";
export const COMPOSITEDEMAND = "COMPOSITEDEMAND";
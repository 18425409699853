import React, { useCallback, useState } from 'react';
import { Typography, Collapse, Stack, Tooltip, Badge, Divider } from '@mui/material';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import ExpandMore from '../../CommonComponents/ExpandMore';
import AiReferencesTreatmentDetailPanel from './AiReferencesTreatmentDetailPanel';
import DataGridWithExpandedDetails from '../../CommonComponents/DataGrid/DataGridWithExpandedDetails';
import { formatPageNumbers, formatDateRange } from '../DemandForms/Utilities';
import { observeEvent } from '../../observability/observability';
import { useParams } from 'react-router-dom';
import { useGetEntityInsightsQuery } from '../../services/insights/insightsApi';
import { grey } from '@mui/material/colors';

const AiReferenceTable = ({ tableData, fieldName = '' }) => {
  const { documentId } = useParams();
  const { data: insightData = [] } = useGetEntityInsightsQuery({ documentId, insightsType: 'ALL' });

  const addInsightDetails = (treatment, insightDataMap) => {
    const newTreatment = { ...treatment };
    for (const [field, value] of Object.entries(treatment)) {
      if (value?.entity_ids) {
        const bboxesByPage = value.entity_ids
          .map((entity_id) => {
            const entityData = insightDataMap.get(entity_id)?.entityInsightData;
            if (entityData?.bboxes && entityData?.generated_page_number != null) {
              return { generated_page_number: entityData.generated_page_number, bboxes: entityData.bboxes };
            }
            return null;
          })
          .filter(Boolean)
          .reduce((page_map, { generated_page_number, bboxes }) => {
            if (!page_map[generated_page_number]) {
              page_map[generated_page_number] = [];
            }
            page_map[generated_page_number].push(...bboxes);
            return page_map;
          }, {});

        if (Object.keys(bboxesByPage).length > 0) {
          newTreatment[field] = { ...value, bounding_boxes: bboxesByPage };
        }
      }
    }
    return newTreatment;
  };

  const aggregateReferenceData = (data) => {
    const dataAggregatedByProvider = {};
    const insightDataMap = new Map((insightData?.map((insight) => [insight.entityInsightId, insight])) || []);

    for (const treatment of data) {
      const treatmentInsight = addInsightDetails(treatment, insightDataMap);
      const providerOrganization = treatmentInsight.provider_organization?.value.toUpperCase();
      const provider = dataAggregatedByProvider[providerOrganization];
      // Treat any invalid date strings as undefined, which will show as an unknown date
      const date = !isNaN(new Date(treatmentInsight.treatment_date?.value))
        ? treatmentInsight.treatment_date?.value
        : undefined;

      if (provider) {
        provider.pages = [...provider.pages, ...treatmentInsight.generated_page_number_list];
        provider.dates.push(date);
        provider.treatments.push({ ...treatmentInsight });
      } else {
        dataAggregatedByProvider[providerOrganization] = {
          facility: providerOrganization,
          pages: [...treatmentInsight.generated_page_number_list],
          dates: [date],
          treatments: [{ ...treatmentInsight }],
        };
      }
    }
    return dataAggregatedByProvider;
  };

  const [expandedTable, setExpandedTable] = useState(false);
  const handleExpandClick = () => {
    setExpandedTable(!expandedTable);
    observeEvent('ai_reference_table_toggled', { custom_field: fieldName, expanded_table: !expandedTable });
  };

  const rows = Object.values(aggregateReferenceData(tableData)).map((row, index) => ({ ...row, id: index + 1 }));

  const columns = [
    {
      field: 'facility',
      headerName: 'Facility',
      width: 400,
      minWidth: 150,
      maxWidth: 700,
      pinnable: false,
      editable: false,
      renderCell: ({ row }) => {
        const unknownFacility = row.facility === undefined;
        return (
          <>
            {unknownFacility && (
              <Tooltip title="AI could not determine the name of this facility" placement="top">
                <WarningAmberOutlined color="warning" sx={{ mr: 1 }} />
              </Tooltip>
            )}
            <Typography variant="tableP1" sx={unknownFacility ? { fontStyle: 'italic' } : {}}>
              {unknownFacility ? 'Unknown facility' : row.facility}
            </Typography>
          </>
        );
      },
    },
    {
      field: 'pages',
      headerName: 'Pages',
      width: 200,
      minWidth: 125,
      maxWidth: 400,
      pinnable: false,
      editable: false,
      renderCell: ({ row }) => {
        return <Typography variant="tableP1">{formatPageNumbers(row.pages)}</Typography>;
      },
    },
    {
      field: 'dates',
      headerName: 'Dates',
      width: 200,
      minWidth: 125,
      maxWidth: 400,
      pinnable: false,
      editable: false,
      filterable: true,
      renderCell: ({ row }) => {
        const unknownDates = row.dates.filter((date) => date === undefined);
        const unknownDateCount = unknownDates.length;
        const allUnknown = unknownDateCount === row.dates.length;
        return (
          <>
            {unknownDateCount > 0 && (
              <Tooltip
                title={`AI could not determine ${unknownDateCount} ${unknownDateCount > 1 ? 'dates' : 'date'} at this facility`}
                placement="top"
              >
                <Badge badgeContent={unknownDateCount} color="warning" sx={{ mr: 2, ml: 1 }} />
              </Tooltip>
            )}
            <Typography variant="tableP1" sx={allUnknown ? { fontStyle: 'italic' } : {}}>
              {allUnknown ? 'Unknown dates' : formatDateRange(row.dates)}
            </Typography>
          </>
        );
      },
    },
  ];

  const getDetailPanelContent = useCallback(
    ({ row }) => <AiReferencesTreatmentDetailPanel treatments={row.treatments} />,
    []
  );

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          '&:hover': {
            backgroundColor: grey[100],
            borderRadius: 1,
          },
          px: 1,
          py: 0.5,
          cursor: 'pointer',
        }}
        onClick={handleExpandClick}
      >
        <Typography variant="body2">References</Typography>
        <ExpandMore expand={expandedTable} size="small" />
      </Stack>
      <Collapse in={expandedTable}>
        <DataGridWithExpandedDetails
          rows={rows}
          columns={columns}
          getDetailPanelContent={getDetailPanelContent}
          density="compact"
        />
      </Collapse>
      <Divider orientation="horizontal" />
    </>
  );
};

export default AiReferenceTable;

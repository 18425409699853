
import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Stack, Divider, Typography, Tooltip, Box } from '@mui/material';
import WarningChip from "../CommonComponents/Chips/WarningChip";
import AddButton from "../CommonComponents/AddButton";
import UpdateContactDialog from "../CommonComponents/Dialogs/UpdateContact";
import { mapDeliveryChannelToLabel, userHasRole } from '../common';
import { PRECEDENT_ADMIN_ROLE, PRECEDENT_EDITOR_ROLE, isPrecedentUser } from "../common-roles";
import KeyValuePair from "./KeyValuePair";
import { matchCarrierByAlias } from "../DemandComponents/DemandForms/FormHelpers";
import { getConfigValue } from '../api';
import { useSelector } from 'react-redux';

const MedicalsOverviewPrecedent = ({ showCarrierPanel, carriersData, saveDeliveryChannel }) => {
    const { user, userData } = useSelector(state => state.User);
    const { documentData, documentMetaData } = useSelector(state => state.Document);

    const [dialogState, setDialogState] = useState({
        updateCarrierEmail: false,
        updateCarrierFax: false,
    });

    const [updateDefaultDeliverySwitched, setUpdateDefaultDeliverySwitched] = useState(false);
    const [useDemandNotesEnabled, setUseDemandNotesEnabled] = useState(false);

    const aliases = []
    const carrierNamesList = []

    if (carriersData) {
        carriersData.forEach(carrier => {
            carrierNamesList.push(carrier.name)
            if (carrier.aliases) {
                carrier.aliases.forEach(alias => {
                    aliases.push(alias)
                })
            }
        })
    }

    const currentCarrierName = documentData?.recipientCarrier?.carrierCommonName || ""
    const currentCarrierEmail = documentData?.recipientCarrier?.contactInfo?.emailAddress || ""
    const currentCarrierFax = documentData?.recipientCarrier?.contactInfo?.faxNumber || ""
    const isKnownCarrier = documentData?.recipientCarrier?.carrierCustomerAliasId && documentData?.recipientCarrier?.carrierCustomerAliasId !== ""
    const carrierInfo = matchCarrierByAlias(documentData?.recipientCarrier?.carrierCommonName, carriersData);

    const deliveryChannels = carrierInfo?.deliveryChannels;
    const defaultDeliveryChannelType = carrierInfo?.defaultDeliveryChannelType;

    const emailChannel = deliveryChannels?.find(channel => channel.deliveryChannelType === 'email');
    const emailDestinationAddress = emailChannel?.deliveryDestinationAddress;
    const faxChannel = deliveryChannels?.find(channel => channel.deliveryChannelType === 'fax');
    const faxDestinationAddress = faxChannel?.deliveryDestinationAddress;

    const showEmailAddButton = (userHasRole(PRECEDENT_ADMIN_ROLE, userData) || userHasRole(PRECEDENT_EDITOR_ROLE, userData))
        && carrierInfo && !emailDestinationAddress && currentCarrierEmail;
    const showFaxAddButton = (userHasRole(PRECEDENT_ADMIN_ROLE, userData) || userHasRole(PRECEDENT_EDITOR_ROLE, userData))
        && carrierInfo && !faxDestinationAddress && currentCarrierFax;

    const showFaxNoMatch = carrierInfo && faxDestinationAddress && (currentCarrierFax !== faxDestinationAddress);
    const showEmailNoMatch = carrierInfo && emailDestinationAddress && (currentCarrierEmail !== emailDestinationAddress);

    const handleUpdateContact = () => {
        if (dialogState.updateCarrierEmail) {
            const deliveryChannel = {
                deliveryChannelType: "email",
                deliveryDestinationAddress: currentCarrierEmail
            }
            // saveDeliveryChannel(carrierInfo.carrierId, deliveryChannel)
            updateDefaultDeliverySwitched && saveDeliveryChannel(carrierInfo.carrierId, deliveryChannel)
        } else if (dialogState.updateCarrierFax) {
            const deliveryChannel = {
                deliveryChannelType: "fax",
                deliveryDestinationAddress: currentCarrierFax
            }
            // saveDeliveryChannel(carrierInfo.carrierId, deliveryChannel)
            updateDefaultDeliverySwitched && saveDeliveryChannel(carrierInfo.carrierId, deliveryChannel)
        }
        setDialogState({ updateCarrierEmail: false, updateCarrierFax: false })
    }

    const collaboratorsList = documentData?.sendingFirm.caseManagers || [];
    const collaboratorsValues = collaboratorsList.length > 0 ? collaboratorsList.map(collaborator => collaborator.firstName + " " + collaborator.lastName) : ['Not on File']

    const loadDemandNotesSetting = async () => {
        setUseDemandNotesEnabled((await getConfigValue('useDemandNotesEnabled', null, user)) === 1);
      };

    useEffect(() => {
        loadDemandNotesSetting();
      }, []);

    return (
        <>
            <Grid container>
                <Grid md={md} sm={sm} xs={xs} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <KeyValuePair label="Loss county" value={documentData?.claimInfo?.lossCounty || "Not on File"} />

                        <KeyValuePair label="Client name" value={documentData?.claimInfo?.claimant?.firstName || "Not on File"} value2={documentData?.claimInfo?.claimant?.lastName ?? ""} />

                        <KeyValuePair label="Insured name" value={documentData?.claimInfo?.insuredFirstName || "Not on File"} value2={documentData?.claimInfo?.insuredLastName ?? ""} />

                    </Stack>
                </Grid>
                <Grid md={md} sm={sm} xs={xs} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <KeyValuePair label="Carrier name" value={currentCarrierName} carrierName={documentData?.recipientCarrier?.carrierCommonName} demandIsDeliverable={documentMetaData.demandIsDeliverable} validation={currentCarrierName && !isKnownCarrier &&
                            isPrecedentUser(userData) && <WarningChip label="No match" />
                        } />

                        <KeyValuePair label="Carrier phone" value={documentData?.recipientCarrier.contactInfo?.phoneNumber || "Not on File"} />

                        <KeyValuePair label="Adjuster name" value={documentData?.recipientAdjuster?.firstName || "Not on File"} value2={documentData?.recipientAdjuster?.lastName ?? ""} />

                        <KeyValuePair label="Adjuster phone" value={documentData?.recipientAdjuster?.contactInfo?.phoneNumber || "Not on File"} />

                    </Stack>
                </Grid>
                <Grid md={md} sm={sm} xs={xs} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <KeyValuePair label="Sending firm" value={documentData?.sendingFirm.firmName} />

                        <KeyValuePair label="Attorney name" value={documentData?.sendingFirm.attorney?.firstName || "Not on File"} value2={documentData?.sendingFirm.attorney?.lastName ?? ""} />

                        <KeyValuePair label="Attorney phone" value={documentData?.sendingFirm.attorney?.contactInfo.phoneNumber || "Not on File"} />

                        <KeyValuePair label="Attorney email" value={documentData?.sendingFirm.attorney?.contactInfo?.emailAddress || "Not on File"} />

                    </Stack>
                </Grid>
                <Grid md={md} sm={sm} xs={xs} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        {collaboratorsValues.map((collaborator, index) => {
                            return (
                                index < 4 ? <KeyValuePair useKeyOptionalStyles={index === 0} key={index} label={index === 0 ? "Case collaborators" : ""} value={collaborator} /> :
                                    index === 4 ?
                                        <Stack direction="row">
                                            <Box sx={{ width: '45%' }}></Box>
                                            <Box sx={{ width: '55%' }}>
                                                <Tooltip title={collaboratorsValues.map((collaborator, index) => {
                                                    return (
                                                        index >= 4 ?
                                                            <Typography key={index} variant="body2">{collaborator}</Typography>
                                                            :
                                                            null
                                                    )
                                                })} arrow placement="left">
                                                    <Typography variant="body2">{`... see ${collaboratorsList.length - 4} more`}</Typography>
                                                </Tooltip>
                                            </Box>
                                        </Stack>
                                        :
                                        null
                            )
                        })}

                        {useDemandNotesEnabled ? <KeyValuePair label="Notes" value={documentData?.customerSpecialNotes || ""} /> : null}

                    </Stack>

                </Grid>
            </Grid>

            {showCarrierPanel ? (
                <>
                    <Divider sx={{ my: 1 }} />
                    <Grid container>
                        <Grid md={md} sm={sm} xs={xs} sx={gridStyles}>
                            <Stack direction={"column"} spacing={stackSpacing}>
                                <KeyValuePair label="Carrier email" value={currentCarrierEmail} validation={showEmailNoMatch && <Tooltip title="Carrier email does not match verified delivery email address">
                                    <WarningChip label="No match" />
                                </Tooltip>} callToAction={showEmailAddButton && <AddButton clickHandler={() => setDialogState({ ...dialogState, updateCarrierEmail: true })} />} />



                            </Stack>
                        </Grid>
                        <Grid md={md} sm={sm} xs={xs} sx={gridStyles}>
                            <KeyValuePair label="Carrier fax" value={currentCarrierFax} validation={showFaxNoMatch && <Tooltip title="Carrier fax does not match verified delivery fax number" >
                                <WarningChip label="No match" />
                            </Tooltip>} callToAction={showFaxAddButton && <AddButton clickHandler={() => setDialogState({ ...dialogState, updateCarrierFax: true })} />} />
                        </Grid>
                        <Grid md={md} sm={sm} xs={xs} sx={gridStyles}>
                            <Stack direction={"column"} spacing={stackSpacing}>
                                <KeyValuePair label="Default delivery" value={mapDeliveryChannelToLabel(defaultDeliveryChannelType)} validation={carrierInfo && !documentData?.defaultDeliveryInformationEnabled && <WarningChip label="Disabled" />} />
                            </Stack>
                        </Grid>

                    </Grid>

                    {(dialogState.updateCarrierEmail || dialogState.updateCarrierFax) && (
                        <UpdateContactDialog
                            dialogOpen={true}
                            dialogTitle={"Update contact"}
                            dialogText={dialogState.updateCarrierEmail ? currentCarrierEmail : currentCarrierFax}
                            cancelButtonText={"Cancel"}
                            okButtonText={"Update contact"}
                            handleUpdateDefaultDeliverySwitched={setUpdateDefaultDeliverySwitched}
                            updateDefaultDeliverySwitched={updateDefaultDeliverySwitched}
                            handleCancel={() => { setDialogState({ updateCarrierEmail: false, updateCarrierFax: false }) }}
                            handleOk={handleUpdateContact}
                            type={dialogState.updateCarrierEmail ? "email" : "fax"}
                            carrierName={currentCarrierName}
                        />
                    )}
                </>
            ) : null}
        </>
    )
}

export default MedicalsOverviewPrecedent;

const gridStyles = { px: 1, py: 1 }
const stackSpacing = 1;
const md = 3;
const sm = 6;
const xs = 6;

import React, { useState } from 'react';
import { Box, Button, Divider, IconButton, TextField, Stack, Popover } from '@mui/material';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { theme } from '../../Theme';
import { useLazyRecordCustomerFeedbackQuery } from '../../services/feedback/customerFeedbackApi';
import { v4 as uuidv4 } from 'uuid';

const ReferenceFeedback = ({ fieldName, fieldContent, fieldContext }) => {
  const [thumbUp, setThumbUp] = useState(false);
  const [thumbDown, setThumbDown] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [eventId, setEventId] = useState(null);

  const [triggerRecordCustomerFeedback] = useLazyRecordCustomerFeedbackQuery();

  const handleThumbUpClick = () => {
    setThumbUp(true);
    setThumbDown(false);
    const id = uuidv4();
    setEventId(id);
    triggerRecordCustomerFeedback({
      id: id,
      name: 'narrative_ai_feedback_positive',
      value: 1,
      context: {
        field_name: fieldName,
        field_value: fieldContent,
        ...fieldContext,
      },
    });
  };

  const handleThumbDownClick = (event) => {
    setThumbDown(true);
    setThumbUp(false);
    const id = uuidv4();
    setEventId(id);
    triggerRecordCustomerFeedback({
      id: id,
      name: 'narrative_ai_feedback_negative',
      value: 1,
      context: {
        field_name: fieldName,
        field_value: fieldContent,
        ...fieldContext,
      },
    });
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => setPopoverAnchorEl(null);

  const handleNegativeFeedbackSubmit = (value) => {
    triggerRecordCustomerFeedback({
      id: eventId,
      name: 'narrative_ai_feedback_negative_info',
      value: 1,
      context: {
        field_name: fieldName,
        field_value: fieldContent,
        additional_info: value,
        ...fieldContext,
      },
    });
    handleClosePopover();
  };

  return (
    <Box>
      <IconButton size="small" onClick={handleThumbUpClick}>
        {thumbUp ? <ThumbUpIcon sx={{ color: theme.palette.primary.light }} /> : <ThumbUpOutlinedIcon />}
      </IconButton>

      <IconButton size="small" onClick={(event) => handleThumbDownClick(event)}>
        {thumbDown ? <ThumbDownIcon sx={{ color: theme.palette.primary.light }} /> : <ThumbDownOutlinedIcon />}
      </IconButton>

      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FeedbackPopoverContent handleClosePopover={handleClosePopover} handleSubmit={handleNegativeFeedbackSubmit} />
      </Popover>
    </Box>
  );
};

const FeedbackPopoverContent = ({ handleClosePopover, handleSubmit }) => {
  const [feedback, setFeedback] = useState('');

  return (
    <Box sx={{ width: '400px' }}>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="(Optional) Please share additional feedback"
        onChange={(event) => setFeedback(event.target.value)}
        sx={{ p: 2 }}
      />
      <Divider />
      <Stack direction="row" spacing={1} p={2} justifyContent="flex-end">
        <Button color="secondary" onClick={handleClosePopover}>
          Dismiss
        </Button>
        <Button color="secondary" variant="contained" onClick={() => handleSubmit(feedback)}>
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

export default ReferenceFeedback;

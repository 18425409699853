import { apiSlice } from '../apiSlice';
import { setToast } from "../../redux/slices/globalToastSlice";

const customerFeedbackApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // POST /customerFeedback
    recordCustomerFeedback: builder.query({
      query: ({id, name, value, context}) => ({
        url: `/customerFeedback`,
        method: 'POST',
        body: JSON.stringify({
          'id': id,
          'name': name,
          'value': value,
          'context': context
        }),
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
          try {
              await queryFulfilled;
              dispatch(setToast({message: 'Thanks for the feedback!', severity: 'success', isOpen: true, duration: 2000}));
          } catch (error) {
              console.log(error);
              dispatch(setToast({message: 'Error recording your feedback, please try again!', severity: 'error', isOpen: true, duration: 9000}));
          }
      }
    }),
  }),
  overrideExisting: false,
});

export const {
  useRecordCustomerFeedbackQuery, useLazyRecordCustomerFeedbackQuery
} = customerFeedbackApi;

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Alert,
  Box,
  Button,
  Tooltip,
  Badge,
  Stack,
  Typography,
} from '@mui/material';
import ConfirmationSwitch from '../CommonComponents/ConfirmationSwitch';
import { Transition } from '../Transition';
import { submitFirmApprovalRequestApi, submitHandlingCompleteRequestApi } from '../api';
import StatusDialog from './StatusDialog';
import { getMedicalTreatmentAlerts } from '../MedicalsComponents/medicals';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDocumentData } from '../redux/thunks/documentData';
import LoadingButton from '@mui/lab/LoadingButton';
import { apiSlice } from '../services/apiSlice';
import { MEDICAL_CHARGES_URL_PATH } from '../MedicalsComponents/insights';
import { setAttorneySend } from '../redux/slices/globalDialogueSlice';
import { pollPdfRegenerationStatus } from '../services/pdf/thunks';

const AttorneySendDialog = ({
  documentId,
  user,
  deliveryEmail,
  viewType,
  handler,
  source
}) => {
  const dispatch = useDispatch();
  const { documentData, documentMetaData } = useSelector((state) => state.Document);
  const documentDataLoading = useSelector((state) => state.Document.documentDataLoading)[documentId] ?? false;
  const { medicals, medicalsLoading } = useSelector((state) => state.Medicals);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
  const [statusDialogText, setStatusDialogText] = useState('');
  const [statusDialogTitle, setStatusDialogTitle] = useState('');
  const [medicalTreatmentAlerts, setMedicalTreatmentAlerts] = useState([]);
  const [switchChecked, setSwitchChecked] = useState(false);
  const hasAlerts = medicalTreatmentAlerts.length > 0;
  const buttonIsDisabled = (hasAlerts && !switchChecked) || documentDataLoading || medicalsLoading;
  const noDemandResponseDueDate = !documentData?.demandDetails?.demandResponseDueDate || documentData?.demandDetails?.demandResponseDueDate === "Invalid date"
  const disabled = buttonIsDisabled;
  const currentDocumentFetched = documentData?.documentId === documentId;

  useEffect(() => {
    if (source === 'inventory') {
      dispatch(fetchDocumentData({ documentId, user }));
      dispatch(
        apiSlice.endpoints.getEntityInsights.initiate(
          { documentId, insightsType: MEDICAL_CHARGES_URL_PATH },
          { forceRefetch: true }
        )
      );
    }
  }, [documentId, user, source, dispatch]);

  useEffect(() => {
    if (!documentDataLoading && !medicalsLoading && currentDocumentFetched) {
      setMedicalTreatmentAlerts(getMedicalTreatmentAlerts(documentData, viewType, true, true, medicals));
    }
  }, [documentDataLoading, medicalsLoading, currentDocumentFetched, documentData, viewType, medicals]);

  const { attorneySend } = useSelector((state) => state.GlobalDialogues);

  const carrierName = documentData?.recipientCarrier?.carrierCommonName;
  const showStatusDialog = (text) => {
    setStatusDialogText(text);
    setStatusDialogOpen(true);
  };

  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false);
    dispatch(setAttorneySend({ open: false }))
  };

  const attorneyApprove = async (userTriggered) => {

    if (userTriggered) {
      setStatusCloseDisabled(true);
    }

    if (documentMetaData?.demandIsDeliverable === false && documentMetaData?.documentStatus === "DocumentApproved") {
      showStatusDialog('Completing handling...');
      submitHandlingCompleteRequestApi(documentId, user).then((response) => {
        if (response.status === 200) {
          showStatusDialog('Success! The demand has been completed.');
          setStatusCloseDisabled(false);
          handler(true);
        } else if (response.status === 201 || response.status === 409) {
          setStatusDialogText('The demand is being regenerated. This should only take a few moments.');
          dispatch(pollPdfRegenerationStatus({documentId}))
            .then((result) => {
              console.log('Polling complete:', result);
              attorneyApprove(false);
            });
        } else {
          showStatusDialog('There was an error completing the demand :-(');
          setStatusCloseDisabled(false);
          handler(false);
        }
      });
    } else {
      showStatusDialog('Sending demand for approval...');
      submitFirmApprovalRequestApi(documentId, user).then((response) => {
        if (response.status === 200) {
          showStatusDialog('Success! The demand has been sent for approval.');
          setStatusCloseDisabled(false);
          handler(true);
        } else if (response.status === 201 || response.status === 409) {
          setStatusDialogText('The demand is being regenerated. This should only take a few moments.');
          dispatch(pollPdfRegenerationStatus({documentId}))
            .then((result) => {
              console.log('Polling complete:', result);
              attorneyApprove(false);
            });
        } else {
          showStatusDialog('There was an error sending the email :-(');
          setStatusCloseDisabled(false);
          handler(false);
        }
      });
    }

  };

  return (
    <>
      <Dialog
        maxWidth=""
        open={attorneySend.open && !statusDialogOpen}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        sx={{ overflow: 'visible' }}
      >
        <DialogContent
          sx={{
            my: 0
          }}
        >
          {hasAlerts && (
            <Box>
              <Stack
                spacing={1}
              >
                {medicalTreatmentAlerts.map((alert, index) => {
                  return (
                    <Alert key={index} severity={alert.alertSeverity}>
                      {alert.alertMessage}
                    </Alert>
                  );
                })}
              </Stack>
              <ConfirmationSwitch
                setSwitchChecked={setSwitchChecked}
                switchChecked={switchChecked}
                labelText="Send demand despite warnings"
              ></ConfirmationSwitch>
            </Box>
          )}

          <DialogContentText id="alert-dialog-slide-description">
            <Stack spacing={2} >
              {documentMetaData?.demandIsDeliverable === false && documentMetaData?.documentStatus === "DocumentApproved" ? (
                <>
                  <Typography variant="body1" color="primary">
                    {documentData?.sendingFirm.firmName} has completed all processing for this demand.<br></br>
                    Note, it will <b>not</b> be delivered to <b>{carrierName}</b>.
                  </Typography>
                  <Typography variant="body1" color="primary">
                    Are you sure you want to complete handling?
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="body1" color="primary">
                    Demand will be delivered to <b>{deliveryEmail}</b>.
                  </Typography>
                  <Typography variant="body1" color="primary">
                    Are you sure you want to send?
                  </Typography>
                </>
              )
              }
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button
            onClick={() => {
              dispatch(setAttorneySend({ open: false }))
            }}
            color="secondary"
            variant="text"
          >
            Cancel
          </Button>

          <Tooltip title={noDemandResponseDueDate && "Add a response due date in edit demand details"} placement='top-end' sx={{ mr: noDemandResponseDueDate && 1 }}>
            <Badge badgeContent={"!"} color="warning" invisible={!noDemandResponseDueDate}>
              <span>
                <LoadingButton
                  disabled={disabled}
                  onClick={() => {
                    setStatusDialogOpen(true);
                    attorneyApprove(true);
                  }}
                  variant="contained"
                  color="secondary"
                  loading={documentDataLoading || medicalsLoading}
                >
                  {documentMetaData?.demandIsDeliverable === false && documentMetaData?.documentStatus === "DocumentApproved" ? 'Complete handling' : 'Send'}
                </LoadingButton>
              </span>
            </Badge>
          </Tooltip>

        </DialogActions>
      </Dialog>

      <StatusDialog
        closeDisabled={statusCloseDisabled}
        handleClose={handleStatusDialogClose}
        dialogOpen={statusDialogOpen}
        dialogText={statusDialogText}
      ></StatusDialog>
    </>
  );
};

export default AttorneySendDialog;

import { apiSlice } from '../apiSlice';

const authForm = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        // POST /authorFormData
        fetchAuthorFormData: builder.query({
            query: ({ customerId = null }) => ({
                url: '/authorFormData',
                method: 'POST',
                body: JSON.stringify({ customerId: customerId })
            }),
        }),
    }),
});

export const {
    useFetchAuthorFormDataQuery,
} = authForm;

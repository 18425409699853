import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import DataGridWithExpandedDetails from '../../CommonComponents/DataGrid/DataGridWithExpandedDetails';
import { useGetAllFilesByDocumentIdQuery } from '../../services/documentFiles/documentFilesApi';
import { viewPageInDocument } from '../../services/pdf/thunks';
import { getDocumentFileByPageNumber } from '../../common-document';

const initialState = {
  sorting: {
    sortModel: [{ field: 'page', sort: 'asc' }],
  },
};

const AiReferencesPageDetailPanel = ({ pages, treatment }) => {
  const dispatch = useDispatch();

  const { documentId } = useParams();

  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId);

  const getDocumentName = (pageNumber) => {
    const file = getDocumentFileByPageNumber(documentFiles, pageNumber);
    return file?.fileEntityData?.fileName ?? '';
  };

  const getPageNavigationLink = (linkText, page, boundingBoxes = []) => (
    <Typography
      variant="tableP1"
      color="secondary"
      onClick={() => dispatch(viewPageInDocument(page, boundingBoxes))}
      sx={{
        cursor: 'pointer',
      }}
    >
      {linkText}
    </Typography>
  );

  // Consolidate all bounding boxes for each page
  const boundingBoxesOnPage = Object.values(treatment).reduce((pageToBBoxesMap, { bounding_boxes }) => {
    if (bounding_boxes) {
      Object.entries(bounding_boxes).forEach(([page, bboxes]) => {
        pageToBBoxesMap[page] = pageToBBoxesMap[page] ? [...pageToBBoxesMap[page], ...bboxes] : [...bboxes];
      });
    }
    return pageToBBoxesMap;
  }, {});

  const rows = pages.map((page, index) => ({
    id: index + 1,
    page,
    boundingBoxes: boundingBoxesOnPage[page] ?? [],
  }));

  const columns = [
    {
      field: 'page',
      headerName: 'Pages',
      width: 150,
      editable: false,
      filterable: true,
      renderCell: ({ row }) => {
        return getPageNavigationLink(row.page, row.page, row?.boundingBoxes);
      },
    },
    {
      field: 'document',
      headerName: 'Document',
      width: 400,
      editable: false,
      filterable: true,
      valueGetter: (params) => getDocumentName(params.row.page),
      renderCell: ({ row }) => {
        const documentName = getDocumentName(row.page);

        return getPageNavigationLink(documentName, row.page, row?.boundingBoxes);
      },
    },
  ];

  return (
    <DataGridWithExpandedDetails
      rows={rows}
      columns={columns}
      isDetailPanel
      initialState={initialState}
      density="compact"
    />
  );
};

export default AiReferencesPageDetailPanel;


import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, Stack, Typography } from '@mui/material';
import { Transition } from '../Transition'
import { faxToCarrierApi } from '../api';
import StatusDialog from "./StatusDialog";
import { pollPdfRegenerationStatus } from '../services/pdf/thunks';
import { useDispatch } from 'react-redux';


const CarrierSendFaxDialog = (props) => {
    const dispatch = useDispatch();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");

    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const faxToCarrier = async (userTriggered) => {
        if (userTriggered) {
            props.handleClose();
            setStatusCloseDisabled(true);
            setStatusDialogTitle("Fax demand");
            showStatusDialog("Sending fax...");
        }

        const documentId = props.documentId;

        faxToCarrierApi(documentId, props.user)
            .then((response) => {
                if (response.status == 200) {
                    showStatusDialog("Success! The fax has been queued up to send.");
                    setStatusCloseDisabled(false);
                    props.handler(true);
                }
                else if (response.status == 201 || response.status == 409) {
                    setStatusDialogText('The demand is being regenerated. This should only take a few moments.');
                    dispatch(pollPdfRegenerationStatus({documentId}))
                      .then((result) => {
                        console.log('Polling complete:', result);
                        faxToCarrier(false);
                      });
                }
                else {
                    setStatusCloseDisabled(false);
                    showStatusDialog("There was an error sending the fax :-(");
                    props.handler(false);
                }
            });
    };

    return (
        <>
            <Dialog
                maxWidth="xs"
                open={props.dialogOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent
                    sx={{
                        marginTop: 0
                    }}
                >
                    <DialogContentText id="alert-dialog-slide-description">
                        <Stack spacing={2}>
                            <Typography variant="body1" color={"primary"}>
                                This demand will be faxed to <b>{props.carrierName} ({props.deliveryFax})</b> by Precedent.
                            </Typography>
                            <Typography variant="body1" color={"primary"}>
                                Are you sure you want to send?
                            </Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.handleClose() }} color="secondary" variant="text">Cancel</Button><Button variant="contained" color="secondary" onClick={() => { faxToCarrier(true) }}>Approve and send</Button>
                </DialogActions>
            </Dialog>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
        </>
    )

}

export default CarrierSendFaxDialog;
import { SvgIcon } from '@mui/material'
import React from 'react'

const NoDeliveryIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9196 7.58068L8.83963 5.40621H17.4361V9.75516H20.6978L23.9596 14.1041V19.5403H22.3592L15.2617 12.1201V7.58068H10.9196ZM16.4475 18.8404L17.5229 19.9647C17.6268 20.2079 17.8171 20.4062 18.0546 20.5206L19.9326 22.484C19.5063 22.6879 19.0285 22.802 18.5234 22.802C16.7186 22.802 15.2617 21.3451 15.2617 19.5403H8.73823C8.73823 21.3451 7.28133 22.802 5.47652 22.802C3.67171 22.802 2.21481 21.3451 2.21481 19.5403H0.0403344V7.58068C0.0403344 6.38472 1.01885 5.40621 2.21481 5.40621H3.59736L5.67729 7.58068H2.21481V17.3658H3.06285C3.66083 16.7026 4.50888 16.2786 5.47652 16.2786C6.44416 16.2786 7.29221 16.7026 7.89019 17.3658H15.037L16.4475 18.8404ZM20.1542 11.386L22.2852 14.1041H17.4361V11.386H20.1542ZM5.47652 20.6275C4.87854 20.6275 4.38928 20.1383 4.38928 19.5403C4.38928 18.9423 4.87854 18.4531 5.47652 18.4531C6.0745 18.4531 6.56376 18.9423 6.56376 19.5403C6.56376 20.1383 6.0745 20.6275 5.47652 20.6275Z" />
            <path d="M15.8077 18.6669L21.689 24.8154L23.3246 23.1055L2.00365 0.81543L0.368042 2.52538L6.24927 8.67394L8.04728 10.5537L14.0097 16.7871L15.8077 18.6669Z" />
        </SvgIcon>
    )
}

export default NoDeliveryIcon
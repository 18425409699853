import { createSlice } from "@reduxjs/toolkit";

const AuthorSlice = createSlice({
    name: "Author",
    initialState: {
        approvers: [],
        carriers: [],
        users: [],

    },
    reducers: {
        // setAuthors: (state, action) => {
        //     // state.authors = action.payload;
        // },
    },
    extraReducers: (builder) => {

    },

});

export const { setAuthors } = AuthorSlice.actions;

export default AuthorSlice.reducer;


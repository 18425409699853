import { SwitchAccountOutlined } from '@mui/icons-material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIntegrationSearch } from '../../../redux/slices/globalDialogueSlice';
import { setToast } from '../../../redux/slices/globalToastSlice';
import {
  useGetIntegrationsForUserQuery,
  useGetIntegrationMetadataQuery,
  useLazyGetIntegrationAuthenticationUrlQuery,
} from '../../../services/integrations/integrationsApi';
import { parseQueryStringToObject } from './helpers';

const SearchIntegrationButton = ({ source }) => {
  const dispatch = useDispatch();

  const {
    integratedCase: { id: matterId, claimNumber },
  } = useSelector((state) => state.Integrations);

  const { data: userIntegrations, isLoading: isUserIntegrationsLoading } = useGetIntegrationsForUserQuery();

  const { data: integrationMetadata = {} } = useGetIntegrationMetadataQuery();
  const { displayName: integrationName, integrationId } = integrationMetadata;

  const [getIntegrationAuthUrl] = useLazyGetIntegrationAuthenticationUrlQuery();

  useEffect(() => {
    const queryParams = parseQueryStringToObject(window.location.search);
    if (queryParams.authSuccess) {
      dispatch(setIntegrationSearch({ open: true, data: { source } }));
      dispatch(setToast({ isOpen: true, message: `Successfully signed into ${integrationName}` }));
    }
  }, [integrationName, dispatch]);

  const handleSearch = async () => {
    const userIntegration = userIntegrations?.find(
      (userIntegration) => userIntegration.integrationId === integrationId
    );

    if (userIntegration && !userIntegration?.isExpired) {
      dispatch(setIntegrationSearch({ open: true, data: { integrationName, integrationId, source } }));
    } else {
      // launch external authentication flow
      // strip url so query params are not included in the redirect url
      const { origin, pathname } = new URL(window.location.href);
      const urlWithoutQueryString = origin + pathname;

      const requestBody = {
        redirectState: { form: source, integration: integrationId },
        redirectUrl: urlWithoutQueryString,
      };
      const { data: authUrlData } = await getIntegrationAuthUrl({ integrationId, payload: requestBody });

      authUrlData && window.location.replace(authUrlData?.authenticationUrl);
    }
  };

  const showIntegration = Boolean(integrationId);
  const isLoading = isUserIntegrationsLoading;

  return (
    showIntegration &&
    (isLoading ? (
      <Stack spacing={2}>
        <Skeleton variant="text" width={150} height={24} />
        <Skeleton variant="rounded" width={130} height={35} />
      </Stack>
    ) : (
      <Stack spacing={1} justifyContent="end">
        <Typography variant="body1" color="primary">
          Select demand from {integrationName}
        </Typography>

        {claimNumber ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1">{claimNumber}</Typography>
            <Button
              color="secondary"
              startIcon={<SwitchAccountOutlined sx={{ fontSize: '1.2rem' }} />}
              onClick={handleSearch}
            >
              Select different demand in {integrationName}
            </Button>
          </Stack>
        ) : (
          <Box>
            <Button variant="outlined" color="secondary" startIcon={<SearchOutlinedIcon />} onClick={handleSearch}>
              Search {integrationName}
            </Button>
          </Box>
        )}
      </Stack>
    ))
  );
};

export default SearchIntegrationButton;

import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { DownloadOutlined } from '@mui/icons-material';
import { updateCsvExportsList } from '../redux/persistedSlices/userHistoryPersistedSlice';
import { postToAuditLog } from "../api";
import { useDispatch, useSelector } from 'react-redux';

const CsvExportIconButton = ({ filteredTreatments, user, documentData, apiRef, pin, columns }) => {

    const dispatch = useDispatch();
    const [ipAddress, setIpAddress] = useState('');
    const { csvExports } = useSelector(state => state.UserHistoryPersisted);

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIpAddress(data.ip));
    }, []);

    const handleCsvExportClick = () => {
        apiRef.current.exportDataAsCsv && apiRef.current.exportDataAsCsv({
            fileName: `medical_treatments_${documentData?.claimInfo.claimNumber}`,
            fields: columns.filter((column) => column.field !== 'gotopage' && column.field !== 'actions').map((column) => column.field)
        });
        if (csvExports.includes(documentData?.documentId)) {
            return;
        } else {
            dispatch(updateCsvExportsList(documentData?.documentId));
            postToAuditLog(user, documentData?.documentId, "MedicalsDownloaded", { "ipAddress": ipAddress, "pin": pin, "userAgent": navigator.userAgent });
        }
    };

    return (

        <Tooltip placement="top" title="Download medical treatments" arrow>
            <span>
                <IconButton
                    onClick={() => handleCsvExportClick()}
                    disabled={!filteredTreatments.length > 0}><DownloadOutlined />
                </IconButton>
            </span>
        </Tooltip>
    )
}

export default CsvExportIconButton;






import { createSlice } from '@reduxjs/toolkit';

const initialIcdCodesSlice = {
  selectedRowsAndIcdCodeIds: {},
  icdCodesUpdateLoading: false,
  icdCodesFilterModel: {
    items: []
  }
};

export const IcdCodesSlice = createSlice({
  name: 'IcdCodes',
  initialState: initialIcdCodesSlice,
  reducers: {
    setSelectedRowsAndIcdCodeIds: (state, action) => {
      state.selectedRowsAndIcdCodeIds = action.payload;
    },
    setIcdCodesUpdateLoading: (state, action) => {
      state.icdCodesUpdateLoading = action.payload
    },
    setIcdCodesFilterModel: (state, action) => {
      state.icdCodesFilterModel = action.payload
    }
  },
});

export const {
  setSelectedRowsAndIcdCodeIds,
  setIcdCodesUpdateLoading,
  setIcdCodesFilterModel
} = IcdCodesSlice.actions;

export default IcdCodesSlice.reducer;

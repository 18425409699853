import React, { useState, useEffect, useMemo } from 'react';
import { Button, Stack, Typography, IconButton, Box, Tooltip, Alert } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { CloudUploadOutlined, DeleteOutlined, Computer } from '@mui/icons-material';
import { setDocumentUploaderList } from '../redux/slices/demandDomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrationLogoUrl } from '../CustomerManagement/Integrations/Helpers';
import { setIntegrationDocumentSelectionModalOpen } from '../redux/slices/integrationsSlice';
import CustomMuiDialogWrapper from '../CommonComponents/CustomMuiDialogWrapper';
import IntegrationsDocumentSelectionModal from './DemandForms/Integrations/DocumentSelectionModal';
import { useGetIntegrationsQuery, useGetIntegrationMetadataQuery } from '../services/integrations/integrationsApi';
import { DOCUMENT_UPLOAD_FILE_TYPES, DOCUMENT_UPLOAD_FILE_TYPES_LONG_VERBOSE, MAX_FILE_SIZE, getDocumentSourceDisplayName } from '../common-document';
import { green } from '@mui/material/colors';

const ROW_HEIGHT = 52; // MUI DataGrid default

const DocumentUploader = ({ heightOffset = '128', isFromDemand = false }) => {
  const dispatch = useDispatch();

  const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery();
  const { data: integrationMetadata = {} } = useGetIntegrationMetadataQuery();
  const { displayName: integrationName, integrationId } = integrationMetadata;

  const { documentUploaderList } = useSelector((state) => state.DemandDom);
  const { integrationDocumentSelectionModalOpen } = useSelector((state) => state.Integrations);
  const { integratedCase: { id: matterId, claimNumber }, } = useSelector((state) => state.Integrations);

  const [borderColor, setBorderColor] = useState('rgba(0,0,0,0.12)');
  const [backgroundColor, setBackgroundColor] = useState('');

  const hasLinkedCase = matterId && claimNumber;

  useEffect(() => {
    return () => dispatch(setDocumentUploaderList([]));
  }, [dispatch]);

  const fileListWithId = documentUploaderList.map((file) => ({
    ...file,
    id: file.name,
    name: file.name,
  }));

  const handleInputChange = (e) => {
    const files = [...e.target.files].map((file) => {
      // Spread operator does not work on file object.

      file.isExternalFile = false;

      const fileType = file.type;
      if (!DOCUMENT_UPLOAD_FILE_TYPES_LONG_VERBOSE.includes(fileType)) {
        file.error = 'Invalid file type';
      } else if (file.size > MAX_FILE_SIZE) {
        file.error = 'File size cannot exceed 150MB';
      }
      return file;
    });


    const newFileList = [...documentUploaderList, ...files];
    const uniqueFiles = newFileList.filter((file, index, self) => index === self.findIndex((t) => t.name === file.name));

    dispatch(setDocumentUploaderList(uniqueFiles));
  };

  const setHoverStyles = () => {
    setBorderColor(green[800]);
    setBackgroundColor(green[50]);
  };

  const clearHoverStyles = () => {
    setBorderColor('rgba(0,0,0,0.12)');
    setBackgroundColor('');
  };

  const handleDragEvent = (e, callBack) => {
    e.preventDefault();
    e.stopPropagation();
    callBack();
  };

  const dragHandlers = {
    onDragEnter: e => handleDragEvent(e, setHoverStyles),
    onDragLeave: e => handleDragEvent(e, clearHoverStyles),
    onDragOver: e => handleDragEvent(e, setHoverStyles),
    onDrop: e => {
      handleDragEvent(e, clearHoverStyles)
      const files = [...e.dataTransfer.files];
      handleInputChange({ target: { files } });
    },
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 360,
      sortable: true,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body1">{params.row.name}</Typography>
          {params.row.error && (
            <Typography variant="caption" color="error">
              {params.row.error}
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      field: 'sourceSystemId',
      headerName: 'Source',
      width: 150,
      minWidth: 100,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body1">
            {!isIntegrationsLoading
              ? getDocumentSourceDisplayName(params.row.sourceSystemId, params.row.detectedType, integrations)
              : ''}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            const newFileList = documentUploaderList.filter((file) => file.name !== params.row.name);
            dispatch(setDocumentUploaderList(newFileList));
          }}
        >
          <DeleteOutlined />
        </IconButton>
      ),
    },
  ];

  const integrationLogo = useMemo(() => (
    <img
      src={getIntegrationLogoUrl(integrationId || '')}
      alt={integrationName}
      style={{
        height: '20px',
        filter: !hasLinkedCase ? 'grayscale(100%) opacity(26%)' : 'none',
      }}
    />
  ), [integrationId, integrationName, hasLinkedCase]);

  const renderNoRowsContent = () => {
    return (
      <Stack
        alignItems="center"
        sx={{
          pt: 12,
          height: '100%',
          px: 4,
        }}
        {...dragHandlers}
      >

        <Typography variant="h6" sx={{ mt: 1, textAlign: 'center' }}>
          Upload police reports, medical bills, and supporting documents
        </Typography>

        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          {Boolean(integrationId) && (
            <Tooltip
              title={
                hasLinkedCase
                  ? ''
                  : `Search and select a case ${isFromDemand ? 'from Edit demand details ' : ''}to upload documents`
              }
            >
              <span style={{ display: 'inline-block' }}>
                <Button
                  startIcon={integrationLogo}
                  variant="outlined"
                  color="secondary"
                  disabled={!hasLinkedCase}
                  sx={{ mt: 2 }}
                  onClick={() => dispatch(setIntegrationDocumentSelectionModalOpen(true))}
                >
                  Upload from {integrationName}
                </Button>
              </span>
            </Tooltip>
          )}

          <span style={{ display: 'inline-block' }}>
            <Button
              startIcon={<Computer />}
              variant="outlined"
              color="secondary"
              component="label"
              sx={{
                mt: 2,
              }}
            >
              Upload from computer
              <input
                accept={DOCUMENT_UPLOAD_FILE_TYPES}
                type="file"
                hidden
                onChange={handleInputChange}
                multiple
              />
            </Button>
          </span>
        </Stack>

        <Typography variant="caption" sx={{ mt: 2, textAlign: 'center', color: 'text.secondary' }}>
          File types: PDF, JPG/JPEG, PNG, TIF/TIFF, DOC/DOCX
        </Typography>

        <Stack alignItems="center" spacing={1} sx={{ mt: 2 }}>
          <CloudUploadOutlined sx={{ fontSize: 50, color: 'secondary.dark' }} />
          <Typography variant="h6" color="secondary.dark">
            Drop files here
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const tableHeight = (fileListWithId.length + 1) * ROW_HEIGHT + 100;
  const hasFileErrors = documentUploaderList.some(file => file.error);


  return (
    <Box
      sx={{
        position: 'relative',
        height: fileListWithId.length ? `${tableHeight}px` : `calc(100vh - ${heightOffset}px)`,
        cursor: 'default',
      }}
      {...dragHandlers}
    >

      {hasFileErrors && (
        <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
          Some files cannot be uploaded. Please check the file types and sizes and delete the invalid files.
        </Alert>
      )}

      <DataGridPro
        rows={fileListWithId}
        columns={columns}
        slots={{
          noRowsOverlay: renderNoRowsContent,
        }}
        sx={{
          backgroundColor: backgroundColor,
          border: '1px solid',
          borderColor: borderColor,
          height: '100%',
          '& .MuiDataGrid-root': {
            border: 'none',
          },
        }}
        hideFooter
        disableColumnMenu
        disableColumnReorder
        disableColumnFilter
        disableColumnSelector
        disableColumnPinning
      />

      <CustomMuiDialogWrapper open={integrationDocumentSelectionModalOpen} fullWidth maxWidth="md">
        <IntegrationsDocumentSelectionModal />
      </CustomMuiDialogWrapper>
    </Box>
  );
};

export default DocumentUploader;
import { userHasPermission } from "../common";

export const scrollToMedicals = (scrollFrameRef) => {
    window.scrollTo({
        top: scrollFrameRef.offsetTop - 125,
        behavior: "smooth"
    });
};

export const isTraditonalDemand = documentType => documentType === "TRADITIONALDEMAND";
export const isCompositeDemand = documentType => documentType === "COMPOSITEDEMAND";

const composeNotAllowedStatuses = ['DocumentApproved', 'DocumentSubmitted', 'DocumentReceived', 'DocumentArchived', 'HandlingCompleted'];
const composeAllowedStatuses = ['DocumentUploaded', 'AwaitingMedicalExtraction', 'AwaitingPrecedentSignoff', 'AwaitingFirmReview','WaitingFirmApproval', 'RejectedByLawFirm'];

export const hasNotAllowedStatus = (metaData) => composeNotAllowedStatuses.includes(metaData?.documentStatus);

export const canComposeDemand = (userData, metaData) => {
    const hasPermission = userHasPermission("DemandCompose", userData)
    const documentStatus = metaData?.documentStatus || '';
    const isCurrentStatusAllowed = composeAllowedStatuses.includes(documentStatus) && !composeNotAllowedStatuses.includes(documentStatus);
    return hasPermission && isCurrentStatusAllowed;
}


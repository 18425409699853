import React from 'react';
import { TextField, Checkbox, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getPageOptions } from '../../common-document';

const SelectPages = ({ documentFiles, pages, handleOnChange }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
  return (
    <Autocomplete
      id="select-page-number"
      multiple
      limitTags={5}
      disableCloseOnSelect
      options={getPageOptions(documentFiles)}
      value={pages}
      onChange={(e, values) => {
        handleOnChange(values.sort((a, b) => a - b));
      }}
      getOptionLabel={(option) => option.toString()}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label="Page" />}
    />
  );
};

export default SelectPages;

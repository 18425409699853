import { useState } from 'react';
import { BulkEditConfirmationDialogContext } from './DialogContext';

const BULK_EDIT_CONFIRMATION_DIALOG_INITIAL_STATE = {
  open: false,
  handleOk: () => {},
  selectedCount: 0,
  source: '',
  rows: [],
};

const BulkEditConfirmationDialogProvider = ({ children, ...props }) => {
  const [bulkEditConfirmation, setBulkEditConfirmation] = useState(BULK_EDIT_CONFIRMATION_DIALOG_INITIAL_STATE);

  const resetBulkEditConfirmation = () => setBulkEditConfirmation(BULK_EDIT_CONFIRMATION_DIALOG_INITIAL_STATE);

  return (
    <BulkEditConfirmationDialogContext.Provider
      value={{ bulkEditConfirmation, setBulkEditConfirmation, resetBulkEditConfirmation }}
      {...props}
    >
      {children}
    </BulkEditConfirmationDialogContext.Provider>
  );
};

export default BulkEditConfirmationDialogProvider;

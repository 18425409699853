import React, { Fragment } from 'react'
import Title from '../CommonComponents/Title'
import { Box, Chip, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { mapStatusToLabel, DOCUMENT_STATUSES_AND_DESCRIPTIONS } from '../common'
import StatusChip from '../CommonComponents/Chips/StatusChip'
import { CheckCircleOutlineOutlined } from '@mui/icons-material'
import { green, grey } from '@mui/material/colors'
import { isPrecedentUser } from '../common-roles'
import { useSelector } from 'react-redux'
import NoDeliveryIcon from '../CommonComponents/Chips/CustomIcons/NoDeliveryIcon'

const DemandWorkflowTooltip = ({ documentStatus, demandIsDeliverable }) => {
  const documentStatusIndex = DOCUMENT_STATUSES_AND_DESCRIPTIONS.findIndex(item => item.status === documentStatus)
  const { userData } = useSelector((state) => state.User);
  const { documentData } = useSelector((state) => state.Document);

  return (
    <Paper
      elevation={8}
      sx={{
        px: 2,
        py: 1,
        display: 'inline-block',
      }}
    >
      <Title>
        Workflow
      </Title>
      {!demandIsDeliverable &&
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          sx={{
            py: 1
          }}
        >
          <NoDeliveryIcon color="action" />
          <Typography
            variant="body2"
            color={grey[700]}
            sx={{
              whiteSpace: 'nowrap'
            }}
          >
            Demand will not be delivered to {documentData.recipientCarrier.carrierCommonName}
          </Typography>
        </Stack>
      }
      <Stack alignItems={'flex-start'} sx={{ mt: .5 }}>
        {DOCUMENT_STATUSES_AND_DESCRIPTIONS.map((item, index) => {
          if (item.status === 'AwaitingPrecedentSignoff' && !isPrecedentUser(userData)) {
            return null
          }

          if ((item.status === 'DocumentReceived' || item.status === 'DocumentSubmitted') && !demandIsDeliverable) {
            return null
          }

          if (item.status === 'HandlingCompleted' && demandIsDeliverable) {
            return null
          }
          return <Fragment key={index}>
            {documentStatus === item.status || (documentStatus == 'AwaitingPrecedentSignoff' && item.status == 'AwaitingMedicalExtraction') ?
              <Tooltip title={item.tooltip} placement='right' arrow>
                <Box>
                  <StatusChip
                    type='document'
                    documentStatus={item.status}
                    showInfoIcon={true}
                    showEditOffIcon={false}
                    demandIsDeliverable={demandIsDeliverable}
                  />
                </Box>
              </Tooltip>
              :
              <Tooltip title={item.tooltip} placement='right' arrow>
                <Box>
                  <Chip
                    label={mapStatusToLabel(item.status, isPrecedentUser(userData))}
                    variant='outlined'
                    icon={index <= documentStatusIndex ?
                      <CheckCircleOutlineOutlined color='success' />
                      : undefined
                    }
                    sx={{
                      background: index <= documentStatusIndex ? green[50] : grey[100],
                      borderColor: index <= documentStatusIndex ? green[800] : grey[400],
                    }}
                  />
                </Box>
              </Tooltip>
            }

            {index !== DOCUMENT_STATUSES_AND_DESCRIPTIONS.length - 1 && item.status !== 'DocumentReceived' &&
              <Divider orientation='vertical' sx={{ height: '16px', pl: 3 }} />
            }
          </Fragment>
        })}
      </Stack>
    </Paper >
  )
}

export default DemandWorkflowTooltip
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useBulkEditConfirmationDialog } from '../dialogs/contexts/DialogContext';

const BulkEditConfirmation = () => {
  const { bulkEditConfirmation, resetBulkEditConfirmation } = useBulkEditConfirmationDialog();
  const { handleOk, selectedCount, source, rows } = bulkEditConfirmation;

  const confirmationTextDictionary = {
    medicalTreatments: {
      headerText: `You are about to edit ${selectedCount} medical treatment${selectedCount > 1 ? 's' : ''}:`,
    },
    editDemandDetails: {
      headerText: `You are about to edit ${selectedCount} value${selectedCount > 1 ? 's' : ''}:`,
    },
  };

  const handleSave = () => {
    handleOk();
    resetBulkEditConfirmation();
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1">{confirmationTextDictionary[source]?.headerText}</Typography>
          <TableContainer
            sx={{
              borderRadius: 1,
              borderLeft: 1,
              borderRight: 1,
              borderTop: 1,
              borderColor: 'divider',
            }}
          >
            <Table
              size="small"
              sx={{
                '&:last-child': {
                  borderBottom: 0,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Field</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Old value</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>New value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.field}>
                    <TableCell>{row.headerName}</TableCell>
                    <TableCell>{row.oldValue}</TableCell>
                    <TableCell>{row.newValue}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="body1">Are you sure you want to continue?</Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={resetBulkEditConfirmation}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSave}
        >
          Apply changes
        </Button>
      </DialogActions>
    </>
  );
};

export default BulkEditConfirmation;


import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, Button, Box, Alert, DialogContentText, Stack, Typography } from '@mui/material';
import { Transition } from '../Transition'
import { approveFirmDemandApi } from '../api';
import StatusDialog from "./StatusDialog";
import { getMedicalTreatmentAlerts } from "../MedicalsComponents/medicals";
import ConfirmationSwitch from '../CommonComponents/ConfirmationSwitch';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDocumentData } from '../redux/thunks/documentData';
import LoadingButton from '@mui/lab/LoadingButton';
import { apiSlice } from '../services/apiSlice';
import { MEDICAL_CHARGES_URL_PATH } from '../MedicalsComponents/insights';
import { pollPdfRegenerationStatus } from '../services/pdf/thunks';

const FirmApproveDialog = ({ handleClose, dialogOpen, documentId, user, handler, viewType, source }) => {
    const dispatch = useDispatch();
    const { documentData, documentMetaData } = useSelector((state) => state.Document);
    const documentDataLoading = useSelector((state) => state.Document.documentDataLoading)[documentId] ?? false;
    const { medicals, medicalsLoading } = useSelector((state) => state.Medicals);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [medicalTreatmentAlerts, setMedicalTreatmentAlerts] = useState([]);
    const [switchChecked, setSwitchChecked] = useState(false);
    const currentDocumentFetched = documentData?.documentId === documentId;
    const hasAlerts = medicalTreatmentAlerts.length > 0;
    const buttonIsDisabled = (hasAlerts && switchChecked) || (!hasAlerts)

    useEffect(() => {
        if (source === 'inventory') {
            dispatch(fetchDocumentData({ documentId, user }));
            dispatch(
                apiSlice.endpoints.getEntityInsights.initiate(
                    { documentId, insightsType: MEDICAL_CHARGES_URL_PATH },
                    { forceRefetch: true }
                )
            );
        }
    }, [documentId, user, source, dispatch]);

    useEffect(() => {
        if (!documentDataLoading && !medicalsLoading && currentDocumentFetched) {
            setMedicalTreatmentAlerts(getMedicalTreatmentAlerts(documentData, viewType, true, true, medicals));
        }
    }, [documentDataLoading, medicalsLoading, currentDocumentFetched, documentData, viewType, medicals]);

    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const approveDemand = (userTriggered) => {

        if (userTriggered) {
            handleClose();
            setStatusCloseDisabled(true);
            showStatusDialog(documentMetaData?.demandIsDeliverable === false ? "Approving the demand..." : "Sending the demand to the carrier...");
        }

        approveFirmDemandApi(documentId, user)
            .then((response) => {
                if (response.status === 200) {
                    showStatusDialog(documentMetaData?.demandIsDeliverable === false ? "The demand has been approved." : "The demand has been sent to " + documentData?.recipientCarrier?.carrierCommonName + ".");
                    setStatusCloseDisabled(false);
                    handler(true);
                }
                else if (response.status === 201 || response.status === 409) {
                    setStatusDialogText('The demand is being regenerated. This should only take a few moments.');
                    dispatch(pollPdfRegenerationStatus({documentId}))
                      .then((result) => {
                        console.log('Polling complete:', result);
                        approveDemand(false);
                      });
                }
                else {
                    showStatusDialog("There was an error sending the email.");
                    setStatusCloseDisabled(false);
                    handler(false);
                }
            });
    };

    return (
        <>
            <Dialog
                maxWidth="xs"
                open={dialogOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                <DialogContent
                    sx={{
                        my: 0
                    }}
                >

                    {hasAlerts && (
                        <Box>

                            <Stack
                                spacing={1}
                            >
                                {medicalTreatmentAlerts.map((alert, index) => {
                                    return (
                                        <Alert key={index} severity={alert.alertSeverity}>{alert.alertMessage}</Alert>
                                    )
                                }
                                )}
                            </Stack>
                            <ConfirmationSwitch
                                setSwitchChecked={setSwitchChecked}
                                switchChecked={switchChecked} labelText='Send demand despite warnings'></ConfirmationSwitch>
                        </Box>
                    )}

                    <DialogContentText id="alert-dialog-slide-description">
                        <Stack spacing={2}>
                            {documentMetaData?.demandIsDeliverable === false ? (
                                <>
                                    <Typography variant="body1" color={"primary"}>
                                        Demand will be approved, but will <b>not</b> be submitted to <b>{documentData?.recipientCarrier?.carrierCommonName || ''}</b>. All edits will be disabled.
                                    </Typography>
                                    <Typography variant="body1" color={"primary"}>
                                        Are you sure you want to approve?
                                    </Typography>
                                </>
                                ) : (
                                <>
                                    <Typography variant="body1" color={"primary"}>
                                        Demand will be submitted to <b>{documentData?.recipientCarrier?.carrierCommonName || ''}</b> by Precedent.
                                    </Typography>
                                    <Typography variant="body1" color={"primary"}>
                                        Are you sure you want to send?
                                    </Typography>
                                </>
                                )
                            }
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color='secondary'
                        onClick={() => { handleClose() }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        disabled={!buttonIsDisabled}
                        variant="contained"
                        color="secondary"
                        onClick={() => { approveDemand(true) }}
                        loading={documentDataLoading || medicalsLoading}
                    >
                        {documentMetaData?.demandIsDeliverable === false ? 'Confirm approval' : 'Approve and send'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
        </>
    )

}

export default FirmApproveDialog;

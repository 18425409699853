import { createSlice } from "@reduxjs/toolkit";
import { fetchDocumentData } from '../thunks/documentData'
import DocumentDataApiSlice from '../../services/documentData/documentDataApi';

const IntegrationsSlice = createSlice({
    name: "Integrations",
    initialState: {
        addIntegrationModalOpen: false,
        integrationDocumentSelectionModalOpen: false,
        integratedCase: {},
        integrationData: {},
        isNewCaseSelected: false,
        selectedRowsForDocumentsUploaded: []
    },
    reducers: {
        setAddIntegrationModalOpen: (state, action) => {
            state.addIntegrationModalOpen = action.payload;
        },
        setIntegrationDocumentSelectionModalOpen: (state, action) => {
            state.integrationDocumentSelectionModalOpen = action.payload;
        },
        setIntegratedCase: (state, action) => {
            state.integratedCase = action.payload;
        },
        setIntegrationData: (state, action) => {
            state.integrationData = action.payload;
        },
        setIsNewCaseSelected: (state, action) => {
            state.isNewCaseSelected = action.payload;
        },
        resetIntegrationState: (state) => {
            state.integratedCase = {};
            state.integrationData = {};
            state.isNewCaseSelected = false;
        },
        setSelectedRowsForDocumentsUploaded: (state, action) => {
            state.selectedRowsForDocumentsUploaded = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => {
                    const isGetDocumentDataAction = action.type === 'api/executeQuery/fulfilled' && action.meta?.arg?.endpointName === 'getDocumentData';
                    return fetchDocumentData?.fulfilled.match(action) || DocumentDataApiSlice.endpoints.getDocumentData?.matchFulfilled(action) || isGetDocumentDataAction;
                },
                (state, action) => {
                    const { document } = action.payload;
                    if (document?.caseManagementMetadata && Object.keys(document?.caseManagementMetadata).length > 0) {
                        state.integratedCase = {
                            id: document?.caseManagementMetadata?.matterTechId,
                            claimNumber: document?.caseManagementMetadata?.matterId,
                        }
                        state.integrationData = {
                            integrationId: document?.caseManagementMetadata?.sourceSystemId,
                        }
                    }
                }
            )
    }
});

export const {
    setAddIntegrationModalOpen,
    setIntegrationDocumentSelectionModalOpen,
    setIntegratedCase,
    setIntegrationData,
    setIsNewCaseSelected,
    resetIntegrationState,
    setSelectedRowsForDocumentsUploaded
} = IntegrationsSlice.actions;

export default IntegrationsSlice.reducer;


import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { Typography, Alert, Box } from '@mui/material';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, AutoAwesome } from '@mui/icons-material';
import {
  MRI_URL_PATH,
  formatBodyPartName,
  transformMriSummaryIntoRows,
  mapPageInsightStatusToDisplayStatus,
  selectPageInsightStatusOptions,
} from './insights';
import { setToast } from '../redux/slices/globalToastSlice';
import { setMriSelectedRows, setMriFindingsUpdateLoading } from '../redux/slices/mriFindingsSlice';
import MedicalsMRIDetailPanelContent from './MedicalsMRIDetailPanelContent';
import TableNoRowsOverlay from './TableNoRowsOverlay';
import { deepPurple } from '@mui/material/colors';
import { userHasPermission, isEdit_Meds_Icds_Mris_Enabled } from '../common';
import { useGetDocumentInsightsQuery, useUpdateEntityInsightDetailsMutation } from '../services/insights/insightsApi';
import { useParams } from 'react-router-dom';
import { useGetDocumentDataQuery } from '../services/documentData/documentDataApi';

const slots = {
  detailPanelExpandIcon: KeyboardArrowDownOutlined,
  detailPanelCollapseIcon: KeyboardArrowUpOutlined,
  noRowsOverlay: TableNoRowsOverlay,
};

const slotProps = {
  noRowsOverlay: {
    text: 'No MRI findings',
  },
};

const MedicalsMRITable = ({ handleViewPageInDocument, userData }) => {
  const { documentId } = useParams();
  const dispatch = useDispatch();

  const { mriFindingsUpdateLoading, mriSelectedRows } = useSelector((state) => state.MriFindings);

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);

  const rowSelectionModel = mriSelectedRows.map((row) => row.id);

  const [columnWidths, setColumnWidths] = useState({}); // State to track column widths
  const apiRef = useGridApiRef(); // Initialize apiRef to access DataGridPro's API
  const getRowHeight = useCallback(() => 'auto', []);

  const handleResizeStop = useCallback(
    (params) => {
      setColumnWidths((prevWidths) => ({
        ...prevWidths,
        [params.colDef.field]: params.colDef.width,
      }));

      // Force a row height recalculation after resizing
      apiRef.current.resetRowHeights();
    },
    [apiRef]
  );

  const { data: mriSummaryData = {}, isFetching: isMriSummaryFetching } = useGetDocumentInsightsQuery({
    documentId,
    insightsType: MRI_URL_PATH,
  });

  const { data: documentData } = useGetDocumentDataQuery({ documentId, pollPdfStatus: false }, { skip: !documentId });

  const [updateMriFinding] = useUpdateEntityInsightDetailsMutation({
    fixedCacheKey: 'update-mri-finding',
  });

  const metadata = documentData?.metadata || {};
  const canEditMriFindings = isEdit_Meds_Icds_Mris_Enabled(metadata?.documentStatus || '');

  const rowsWithoutId = transformMriSummaryIntoRows(mriSummaryData);
  const rows = rowsWithoutId.map((row, index) => ({
    id: index + 1,
    ...row,
  }));

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    },
    {
      field: 'value',
      headerName: 'Description',
      pinnable: false,
      width: columnWidths['value'] || 500, // Track custom width
      minWidth: 200,
      editable: canEditMriFindings,
      renderCell: ({ row }) => {
        const { value } = row;
        return <Typography variant="tableP1">{value}</Typography>;
      },
    },
    {
      field: 'bodyPart',
      headerName: 'Body part',
      pinnable: false,
      width: columnWidths['bodyPart'] || 200,
      minWidth: 125,
      renderCell: ({ row }) => {
        return <Typography variant="tableP1">{`${formatBodyPartName(row.bodyPart)}`}</Typography>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      pinnable: false,
      resizable: false,
      editable: canEditMriFindings,
      filterable: true,
      type: 'singleSelect',
      valueOptions: selectPageInsightStatusOptions,
      renderCell: (params) => {
        return <Typography variant="tableP1">{mapPageInsightStatusToDisplayStatus[params.row.status]}</Typography>;
      },
    },
  ];

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <MedicalsMRIDetailPanelContent
        mriValue={row.value}
        mriEntityIds={row.entityIds}
        documentId={documentId}
        handleViewPageInDocument={handleViewPageInDocument}
      />
    ),
    [documentId, handleViewPageInDocument]
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds);
  }, []);

  const getRowClassName = useCallback(
    (params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd'),
    []
  );

  const handleRowSelectionChange = useCallback(
    (newRowSelectionModel, details) => {
      const selectedRows = [...details.api.getRowModels()]
        .map((rowModelArray) => rowModelArray[1])
        .filter((row) => newRowSelectionModel.includes(row.id));
      dispatch(setMriSelectedRows(selectedRows));
    },
    [dispatch]
  );

  const processRowUpdate = (updatedRow, originalRow) => {
    if (updatedRow.status !== originalRow.status || updatedRow.value !== originalRow.value) {
      dispatch(setMriFindingsUpdateLoading(true));
      updateMriFinding({
        documentId,
        insightsType: MRI_URL_PATH,
        entitiesToUpdate: [{ entityIds: updatedRow.entityIds, uuid: updatedRow.uuid }],
        newStatus: updatedRow.status,
        newValue: updatedRow.value,
      })
        .unwrap()
        .catch((error) => {
          console.log('error', error);
          dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with updating MRI finding' }));
          dispatch(setMriFindingsUpdateLoading(false));
        });
    }
    return originalRow;
  };

  const handleProcessRowError = useCallback((error) => console.log(error), []);

  return (
    <>
      <Alert
        icon={<AutoAwesome fontSize="small" sx={{ color: deepPurple[900] }} />}
        severity="info"
        sx={{ mb: 1, backgroundColor: deepPurple[50], color: deepPurple[900] }}
      >
        Extracted by AI from medical bills and records
      </Alert>
      {/* These two boxes are a bit of a hack to force the browser to calculate the DataGrid's dimensions, allowing it to be height responsive. */}
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box sx={{ position: 'absolute', inset: 0 }}>
          <DataGridPro
            loading={isMriSummaryFetching || mriFindingsUpdateLoading}
            rows={rows}
            columns={columns}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            disableRowSelectionOnClick
            disableColumnReorder
            disableColumnSelector
            disableColumnPinning={false}
            slots={slots}
            slotProps={slotProps}
            getRowClassName={getRowClassName}
            checkboxSelection={userHasPermission('DemandCompose', userData) && canEditMriFindings}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowError}
            onRowSelectionModelChange={handleRowSelectionChange}
            rowSelectionModel={rowSelectionModel}
            getRowHeight={getRowHeight}
            onColumnWidthChange={handleResizeStop} // Capture column resize
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field],
              },
            }}
            apiRef={apiRef} // Attach apiRef
          />
        </Box>
      </Box>
    </>
  );
};

export default MedicalsMRITable;

import { FilterAlt, FilterAltOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setMedicalTreatmentsFilterModel } from '../redux/slices/medicalsTreatmentsSlice';
import { createFilterId, getCurrentDate } from '../common';


const MedicalTreatmentAlertActionButton = ({ alertType }) => {
    const dispatch = useDispatch();

    const { medicalTreatmentsFilterModel } = useSelector((state) => state.MedicalsTreatments);
    const documentDataState = useSelector((state) => state.Document);
    const documentData = documentDataState.documentData;

    const today = getCurrentDate();

    const hasFilteredTreatmentsBeforeDOL = medicalTreatmentsFilterModel.items.some((item) => item.value === documentData.claimInfo.dateOfLoss && item.operator === 'before');

    const hasFilteredTreatmentsAfterToday = medicalTreatmentsFilterModel.items.some((item) => item.value === today && item.operator === 'after');

    const handleFilterFromTreatmentsBeforeDateOfLossAlert = () => {
        if (hasFilteredTreatmentsBeforeDOL === false) {
            dispatch(setMedicalTreatmentsFilterModel({
                items: [
                    ...medicalTreatmentsFilterModel.items,
                    {
                        id: createFilterId(),
                        field: 'treatmentDate',
                        operator: 'before',
                        value: documentData.claimInfo.dateOfLoss,
                    }
                ]
            }));
        } else if (hasFilteredTreatmentsBeforeDOL === true) {
            const filterItemsLessAutoAlertFilter = medicalTreatmentsFilterModel.items.filter((item) => item.value !== documentData?.claimInfo?.dateOfLoss && item.operator !== 'before');

            dispatch(setMedicalTreatmentsFilterModel({
                items: [...filterItemsLessAutoAlertFilter]
            }))
        }
    }

    const handleFilterTreatmentsAfterToday = () => {
        if (hasFilteredTreatmentsAfterToday === false) {
            dispatch(setMedicalTreatmentsFilterModel({
                items: [
                    ...medicalTreatmentsFilterModel.items,
                    {
                        id: createFilterId(),
                        field: 'treatmentDate',
                        operator: 'after',
                        value: today
                    }
                ]
            }));
        } else if (hasFilteredTreatmentsAfterToday === true) {
            const filterItemsLessTreatmentsAfterToday = medicalTreatmentsFilterModel.items.filter((item) => item.value !== today && item.operator !== 'after');

            dispatch(setMedicalTreatmentsFilterModel({
                items: [...filterItemsLessTreatmentsAfterToday]
            }))
        }
    }


    const isFiltered = alertType === 'treatmentBeforeDateOfLoss'
        ? hasFilteredTreatmentsBeforeDOL
        : hasFilteredTreatmentsAfterToday;

    const handleClick = alertType === 'treatmentBeforeDateOfLoss'
        ? handleFilterFromTreatmentsBeforeDateOfLossAlert
        : handleFilterTreatmentsAfterToday;

    return (
        <Button
            color="inherit"
            variant={isFiltered ? "outlined" : "text"}
            startIcon={isFiltered ? <FilterAlt /> : <FilterAltOutlined />}
            size="small"
            onClick={handleClick}
        >
            {isFiltered ? "Remove filters" : "Filter treatments"}
        </Button>
    );
}

export default MedicalTreatmentAlertActionButton
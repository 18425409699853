import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { Transition } from '../Transition';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack } from '@mui/material';
import { ICD_CODES_ENTITY_INSIGHTS_TYPE, INCLUDED_STATUS } from '../MedicalsComponents/insights';
import { setToast } from '../redux/slices/globalToastSlice';
import ICDCodeAutocomplete from '../MedicalsComponents/ICDCodes/ICDCodeAutocomplete';
import SelectPages from '../CommonComponents/Inputs/SelectPages';
import { v4 as uuidv4 } from 'uuid';
import { getDocumentFileByPageNumber } from '../common-document';
import { useAddEntityInsightDetailsMutation } from '../services/insights/insightsApi';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';
import { setIcdCodesUpdateLoading } from '../redux/slices/icdCodesSlice';

const AddICDCode = () => {
  const dispatch = useDispatch();

  const { addICDCode } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = addICDCode;
  const { documentId, user } = data;

  const [icdCodeFormValue, setIcdCodeFormValue] = useState(['', '']);
  const [icdCode, description] = icdCodeFormValue;

  const [pages, setPages] = useState([]);

  const isIcdCodeDirty = icdCode !== '';
  const isPageDirty = pages.length > 0;

  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId);

  const [createIcdCode] = useAddEntityInsightDetailsMutation({
    fixedCacheKey: 'update-icd-code',
  });

  const handleIcdCodeChange = (value) => {
    if (Array.isArray(value)) {
      setIcdCodeFormValue(value);
    } else if (value === null) {
      setIcdCodeFormValue(['', '']);
    }
  };

  const handleSaveClick = () => {
    dispatch(setIcdCodesUpdateLoading(true));
    const newIcdEntities = pages.map((generatedPageNumber) => {
      const file = getDocumentFileByPageNumber(documentFiles, generatedPageNumber);

      // Calculate file page number
      const page_number = generatedPageNumber - file?.fileEntityData?.startPage + 1 || 1;

      return {
        entityInsightId: uuidv4(),
        entityInsightType: ICD_CODES_ENTITY_INSIGHTS_TYPE,
        fileEntityId: file?.fileEntityId,
        entityInsightData: {
          icd_code_id: uuidv4(),
          value: icdCode,
          description,
          status: INCLUDED_STATUS,
          needs_review: false,
          needs_review_reason_internal: [],
          bboxes: [],
          page_number,
        },
      };
    });

    createIcdCode({
      documentId,
      newEntityInsights: newIcdEntities,
    })
      .unwrap()
      .catch((error) => {
        console.log('error', error);
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with adding ICD code' }));
        dispatch(setIcdCodesUpdateLoading(false));
      });

    dispatch(resetGlobalDialogues());
  };

  const handleClose = () => {
    dispatch(resetGlobalDialogues());
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth TransitionComponent={Transition}>
      <DialogTitle>Add ICD code</DialogTitle>

      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <ICDCodeAutocomplete value={icdCodeFormValue} onChange={handleIcdCodeChange} user={user} />
          <SelectPages
            documentFiles={documentFiles}
            pages={pages}
            handleOnChange={(newValues) => setPages(newValues)}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>

        <Button
          disabled={!isIcdCodeDirty || !isPageDirty}
          onClick={handleSaveClick}
          variant="contained"
          color="secondary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddICDCode;

import { Paper, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import Title from '../../CommonComponents/Title';
import { useSelector } from 'react-redux';
import { CheckCircle, Error, RadioButtonUnchecked } from '@mui/icons-material';
import { requiredFieldsForTooltip } from '../../document-fields';

const findErrorPath = (obj, errorString, path = "") => {
    const result = [];

    // Iterate through all properties of the object
    for (const key in obj) {
        // Check if the property is an object and recurse into it
        if (typeof obj[key] === "object" && obj[key] !== null) {
            findErrorPath(obj[key], errorString, `${path}${key}.`).forEach(subPath => {
                result.push(subPath);
            });
        } else if (obj[key] && errorString !== "") {
            // If the property contains the error string, add its path to the result
            result.push(`${path}${key}`);
        }
    }

    return result;
};

const RequiredFieldsTooltip = ({ errors, touched, values, children, valid, hasUploadErrors }) => {
    const { documentUploaderList } = useSelector((state) => state.DemandDom);
    const haveFilesUploaded = documentUploaderList.length > 0;
    const errorArray = findErrorPath(errors);
    const hasDocumentError = documentUploaderList.some((file) => file.error !== null);

    const documentsIcon = () => {
        if (!haveFilesUploaded) {
            return <RadioButtonUnchecked color="disabled" />
        } else if (haveFilesUploaded && !hasDocumentError) {
            return <CheckCircle color="success" />
        } else if (hasDocumentError) {
            return <Error color="error" />
        }
    }

    return (
        <Tooltip
            title={
                valid && haveFilesUploaded && !hasUploadErrors ? ''
                    :
                    <Paper
                        elevation={8}
                        sx={{
                            px: 2,
                            py: 1
                        }}
                    >
                        <Stack
                            spacing={1}
                        >
                            <Title>Required fields</Title>
                            {/* iterate through required fields */}
                            {
                                requiredFieldsForTooltip.map((field) => {

                                    //conditionally exclude these required fields from the tooltip
                                    if (field.path === 'claimInfo.injuredPartyFullName' && values.claimInfo.clientIsInjuredParty === true) {
                                        return
                                    }
                                    if (field.path === 'claimInfo.tortfeasorFullName' && values.claimInfo.insuredIsTortfeasor === true) {
                                        return
                                    }
                                    if (field.path === 'autoCompletes.primaryContact' && values.mutators.attorneyIsPrimaryContact === true) {
                                        return
                                    }

                                    if (errorArray.includes(field.path) && !touched[field.path]) {
                                        //default unchecked display
                                        return (
                                            <Stack
                                                key={field.path}
                                                direction="row"
                                                spacing={1}
                                            >
                                                <RadioButtonUnchecked
                                                    color="disabled"
                                                />
                                                <Typography
                                                    variant="body1"
                                                >
                                                    {field.label}
                                                </Typography>
                                            </Stack>
                                        );
                                    }
                                    else if (errorArray.includes(field.path) && touched[field.path]) {
                                        //error display
                                        return (
                                            <Stack
                                                key={field.path}
                                                direction="row"
                                                spacing={1}
                                            >
                                                <Error color="error" />
                                                <Typography
                                                    variant="body1"
                                                >
                                                    {field.label}
                                                </Typography>
                                            </Stack>
                                        );
                                    } else {
                                        //successfully completed the field
                                        return (
                                            <Stack
                                                key={field.path}
                                                direction="row"
                                                spacing={1}
                                            >
                                                <CheckCircle
                                                    color="success"
                                                />
                                                <Typography
                                                    variant="body1"
                                                >
                                                    {field.label}
                                                </Typography>
                                            </Stack>
                                        );
                                    }
                                })
                            }
                            {/* add documents to the bottom of the required fields list */}
                            <Stack
                                direction={"row"}
                                spacing={1}
                                key="required-documents"
                            >
                                {documentsIcon()}
                                <Typography
                                    variant="body1"
                                >
                                    Documents
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
            }
            placement="right-start"
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [-6, -16],
                            },
                        },
                    ],
                },
            }}
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: 'transparent',
                    },
                },
            }}
        >
            <span>
                {children}
            </span>
        </Tooltip>
    );
};

export default RequiredFieldsTooltip;


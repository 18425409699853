import React, { useEffect } from 'react';
import { Resizable } from 're-resizable';
import { grey } from '@mui/material/colors'
import { Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { set_component_A_width, set_component_B_width } from '../redux/slices/sideBySideWithVerticalNavSlice';
import { ResizableHandle as ResizableCustomHandle } from '@prec-ui/components';
import { CLOSE_TRANSITION_TIME } from '../redux/thunks/sideBysideWithVerticalNav';
import '../App.css'

const ResizableSideBySide = ({
    children
}) => {
    const dispatch = useDispatch();
    const { component_A_width, component_B_width, component_B_current_minimum_width, isClosed, enableCloseTransition, settings } = useSelector((state) => state.SideBySideWithVerticalNav);  //redux state
    const windowWidth = window.innerWidth
    const [ComponentA, ComponentB] = children;

    const handleWindowResize = (e) => {
        const newWindowWidth = e.target.innerWidth
        const difference = newWindowWidth - windowWidth;
        dispatch(set_component_A_width(component_A_width + difference));
    }

    useEffect(() => {  // when browser window is resized, adjust b component to keep on screen
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, [component_B_width])


    useEffect(() => {  // when browser window is resized, adjust b component to keep on screen
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, [component_B_width])

    useEffect(() => {
        dispatch(set_component_A_width(windowWidth / 2))
    }, [])

    return (
        <>
            <Resizable
                enable={{
                    right: true,
                }}
                maxWidth={windowWidth - component_B_current_minimum_width}
                minWidth={settings.component_A_minimum_width}
                onResizeStop={(e, direction, ref, d) => {
                    dispatch(set_component_A_width(component_A_width + d.width));
                    dispatch(set_component_B_width(component_B_width - d.width));
                }}
                size={{
                    width: component_A_width,
                }}
                handleClasses={{
                    right: 'admin-handle-right'
                }}
                style={{
                    transition: enableCloseTransition ? `width ${CLOSE_TRANSITION_TIME}ms` : 'none',
                }}

            >

                <div
                    style={{
                        overflow: 'hidden',
                        width: '100%',
                    }}
                >
                    {/* left hand Lower Order Component (LOC) */}
                    {ComponentA}
                </div>

            </Resizable>

            <Stack direction={'row'} sx={{ height: '100%', border: `1px solid ${grey[300]}`, flex: 1 }}>
                {ComponentB}
            </Stack>
        </>
    )
}

export default ResizableSideBySide;

import React, { useCallback } from 'react';
import { Box, Button, DialogContent, DialogTitle, DialogActions, Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Folder as FolderIcon, InsertDriveFile as InsertDriveFileIcon, Image as ImageIcon, LocalHospital as LocalHospitalIcon, Description as DescriptionIcon, SwitchAccountOutlined } from '@mui/icons-material';
import { getIntegrationLogoUrl } from '../../../CustomerManagement/Integrations/Helpers';
import { useGetDocumentsForMatterQuery } from '../../../services/integrations/integrationsApi';
import { setIntegrationDocumentSelectionModalOpen, setSelectedRowsForDocumentsUploaded } from '../../../redux/slices/integrationsSlice';
import { setIntegrationSearch } from '../../../redux/slices/globalDialogueSlice';
import { setDocumentUploaderList } from '../../../redux/slices/demandDomSlice';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../../CommonComponents/Title';

const dataGridStyles = {
    border: '1px solid',
    borderColor: 'divider',
    flex: 1,
    '& .MuiDataGrid-cell': {
        borderBottom: '1px solid #f0f0f0',
    },
};

const DocumentSelectionModal = () => {

    const dispatch = useDispatch();
    const { documentUploaderList } = useSelector((state) => state.DemandDom);

    const {
        integratedCase: { id: matterId, claimNumber },
        integrationData: { integrationId = '', integrationName = '' },
        selectedRowsForDocumentsUploaded: selectedRows
    } = useSelector((state) => state.Integrations);

    const { data: documents = [], isLoading } = useGetDocumentsForMatterQuery({ matterId, integrationId });

    const getFileIcon = (fileName) => {  //todo: move to helpers and have David audit
        if (fileName.endsWith('.pdf')) return <InsertDriveFileIcon color="action" />;
        if (fileName.endsWith('.jpeg')) return <ImageIcon color="action" />;
        if (fileName.endsWith('.docx')) return <DescriptionIcon color="action" />;
        return <InsertDriveFileIcon color="action" />;
    };

    const getFolderIcon = (folder) => {  //todo: move to helpers and have David audit
        switch (folder) {
            case 'Medical attachments':
                return <LocalHospitalIcon color="secondary" />;
            default:
                return <FolderIcon color="secondary" />;
        }
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {getFileIcon(params.row.name)}
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'folder',
            headerName: 'Folder',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {getFolderIcon(params.value)}
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'receivedAt',
            headerName: 'Uploaded',
            flex: 1,
            valueFormatter: (params) => {
                return new Date(params.value).toLocaleString();
            }
        },
    ];

    const handleClose = () => {
        dispatch(setIntegrationDocumentSelectionModalOpen(false));
        dispatch(setSelectedRowsForDocumentsUploaded([]))
    };

    const handleUpload = (selectedRows) => {
        dispatch(setDocumentUploaderList(

            documentUploaderList
                .filter(doc => !doc.isExternalFile)
                .concat(
                    documents
                        .filter(doc => selectedRows.includes(doc.id))
                        .map(doc => (
                            {
                                ...doc,
                                type: doc.contentType,
                                isExternalFile: true,
                                externalFileId: doc.id,
                                sourceSystemId: integrationId,
                            }
                        ))
                )
        ));

        handleClose();
    };

    const handleSearch = () => {
        dispatch(setIntegrationSearch({ open: true, data: { integrationId, integrationName } }));
        handleClose();
    };

    const handleRowSelectionChange = useCallback((newSelection) => {
        dispatch(setSelectedRowsForDocumentsUploaded(newSelection));
    }, [dispatch]);

    return (
        <>
            <DialogTitle>
                <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                >
                    <img
                        src={getIntegrationLogoUrl(integrationId)}
                        alt={integrationName}
                        style={{
                            height: 32,
                        }}
                    />
                    <Title>
                        Documents
                    </Title>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Stack spacing={1} >
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        sx={{
                            pt: 2
                        }}
                    >
                        <Title>
                            {claimNumber}
                        </Title>
                        <Button
                            color="secondary"
                            startIcon={<SwitchAccountOutlined />}
                            onClick={handleSearch}
                        >
                            Select different demand in {integrationName}
                        </Button>
                    </Stack>
                    <Box
                        sx={{
                            height: 500
                        }}
                    >
                        <DataGridPro
                            loading={isLoading}
                            rows={documents}
                            columns={columns}
                            checkboxSelection
                            rowSelectionModel={selectedRows}
                            onRowSelectionModelChange={handleRowSelectionChange}
                            sx={dataGridStyles}
                        />
                    </Box>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    disabled={selectedRows.length === 0}
                    onClick={() => {
                        handleUpload(selectedRows);
                    }}
                >
                    Upload documents
                </Button>
            </DialogActions>
        </>
    );
};

export default DocumentSelectionModal;
import { createSlice } from '@reduxjs/toolkit';

const originalGlobalToastState = {
  toast: {
    isOpen: false,
    severity: 'success',
    message: '',
    duration: 5000,
    keepOpenOnClickAway: false,
  },
  apiErrorToast: {
    isOpen: false,
    severity: 'error',
    message: '',
    errorPayload: {},
    duration: 5000,
    keepOpenOnClickAway: false,},
};

export const globalToastSlice = createSlice({
  name: 'GlobalToasts',
  initialState: originalGlobalToastState,
  reducers: {
    setToast: (state, action) => {
      state.toast = {
        ...state.toast,
        ...action.payload,
      };
    },
    setApiErrorToast: (state, action) => {
      state.apiErrorToast = action.payload;
    },
    resetGlobalToasts: () => originalGlobalToastState,
  },
});

export const { setToast, setApiErrorToast, resetGlobalToasts } = globalToastSlice.actions;

export default globalToastSlice.reducer;

import { Extension } from '@tiptap/core';
import { generateNarrativeForField } from '../../../api';
import { setToast } from '../../../redux/slices/globalToastSlice';
import { setFieldsWithGeneratedNarrative, setAIReferencesForField } from '../../../services/aiNarrativeGeneration/customSlice';
import store from '../../../redux/store';

const TIPTAP_PARAGRAPH_START = '<p style="margin-left: 0px!important">';

const formatHTMLResponse = (response) => {
    response = response.replace(/^(<html>)|(<\/html>)$/, ''); // strip opening and closing <html> tags
    response = response.replaceAll('\n', ''); // remove all newlines
    return response;
};

const formatTextResponse = (response) => {
    response = response.replace(/^"(.+)"$/, "$1"); // remove start and end quotes, if both exist
    response = TIPTAP_PARAGRAPH_START + response.replaceAll('\n', '</p>' + TIPTAP_PARAGRAPH_START) + '</p>'; // replace newlines and wrap in paragraphs
    return response;
};

const formatResponse = (response) => {
    const typingResponse = response.replaceAll('\n', '</br>'); // used only for the typing animation, since content wrapped in <p> tags doesn't render correctly when typing
    const formattedResponse = response.startsWith('<html>') && response.endsWith('</html>')
        ? formatHTMLResponse(response)
        : formatTextResponse(response);
    return { formattedResponse, typingResponse }
};

const typeResponse = async (
  editor,
  formattedResponse,
  typingDurationLowerBound,
  typingDurationUpperBound,
  wordsPerUpdate
) => {
  const words = formattedResponse.split(' ');
  const totalWords = words.length;
  const typingDuration = Math.floor(
    Math.random() * (typingDurationUpperBound - typingDurationLowerBound + 1) + typingDurationLowerBound
  );
  const intervalTime = typingDuration / Math.ceil(totalWords / wordsPerUpdate);

  for (let i = 0; i < totalWords; i += wordsPerUpdate) {
    const chunk = words.slice(i, i + wordsPerUpdate).join(' ');

    editor
      .chain()
      .command(({ commands, tr }) => {
        const { $to } = tr.selection;
        const endPosition = $to.end();
        commands.insertContentAt(endPosition, chunk + ' ', {
          parseOptions: {
            preserveWhitespace: 'full',
          },
        });
      })

      .run();
    await new Promise((resolve) => setTimeout(resolve, intervalTime));
  }
};

const AiGenerate = Extension.create({
  name: 'aiGenerate',

  addStorage() {
    return {
      isLoading: false,
    };
  },

  addCommands() {
    return {
      generateNarrative:
        (fieldName, documentId, user) =>
        ({ editor }) => {
          this.storage.isLoading = true;
          generateNarrativeForField(fieldName, documentId, user)
            .then((response) => {
              if (response.status === 200) {
                const responseText = response.body.content;
                const aiReferences = response.body.treatmentEncounters;
                const oldContent = editor.getText() !== '' ? editor.getHTML() : ''; // old content in the field will already be wrapped in <p> tags
                const { formattedResponse, typingResponse } = formatResponse(responseText, editor.getText() !== '');
                typeResponse(editor, typingResponse, 3000, 5000, 2) // take anywhere from 3 to 5 seconds to type out the response using 2 words per update
                  .then(() => editor.commands.setContent(oldContent + formattedResponse)); // replace wih fully formatted text
                store.dispatch(setFieldsWithGeneratedNarrative({[fieldName]: true}));
                store.dispatch(setAIReferencesForField({[fieldName]: aiReferences}));
              } else {
                store.dispatch(
                  setToast({
                    isOpen: true,
                    severity: 'error',
                    message: 'Error generating AI narrative',
                    duration: 7000,
                  })
                );
              }
            })
            .finally(() => {
              this.storage.isLoading = false;
            });

          return true;
        },
    };
  },
});

export default AiGenerate;
